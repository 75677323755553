/**
 * The version of the OpenAPI document: v7
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Observable } from 'rxjs';
import { requestAuthenticated, Optional, RequestOption, HttpHeaders, HttpQuery, throwIfNullOrUndefined, encodeURI } from '../runtime';
import {
    PageOfRESTItemComposed,
    PageOfRESTSharePointLibrary,
    RESTExceptionInfo,
    RESTListRestoreStatistics,
    RESTRestoreListConfig,
    RESTRestoreToListConfig,
    RESTSendAsMsgOptionsV6,
    RESTSharePointLibrary,
    RESTVESPSearchOptions,
} from '../models';

export interface SharePointLibraryApiSharePointLibraryGetRequest {
    restoreSessionId: string;
    siteId: string;
    offset: number;
    limit: number;
}

export interface SharePointLibraryApiSharePointLibraryGetByIdRequest {
    restoreSessionId: string;
    siteId: string;
    libraryId: string;
}

export interface SharePointLibraryApiSharePointLibraryRestoreLibraryActionRequest {
    restoreSessionId: string;
    siteId: string;
    libraryId: string;
    body: RESTRestoreListConfig;
}

export interface SharePointLibraryApiSharePointLibraryRestoreLibraryActionToRequest {
    restoreSessionId: string;
    siteId: string;
    libraryId: string;
    body: RESTRestoreToListConfig;
}

export interface SharePointLibraryApiSharePointLibrarySaveLibraryActionRequest {
    restoreSessionId: string;
    siteId: string;
    libraryId: string;
}

export interface SharePointLibraryApiSharePointLibrarySearchLibraryByOptionsRequest {
    restoreSessionId: string;
    siteId: string;
    libraryId: string;
    offset: number;
    limit: number;
    body: RESTVESPSearchOptions;
    setId?: string;
    itemType?: SharePointLibrarySearchLibraryByOptionsItemTypeEnum;
}

export interface SharePointLibraryApiSharePointLibrarySendLibraryActionRequest {
    restoreSessionId: string;
    siteId: string;
    libraryId: string;
    body: RESTSendAsMsgOptionsV6;
}

/**
 * no description
 */
export const sharePointLibraryApi = ({

    /**
     * Returns a collection of backed-up SharePoint document libraries to explore and restore.
     * Get SharePoint Libraries
     */
    sharePointLibraryGet: ({ restoreSessionId, siteId, offset, limit }: SharePointLibraryApiSharePointLibraryGetRequest, option?: RequestOption): Observable<Optional<PageOfRESTSharePointLibrary>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'sharePointLibraryGet');
        throwIfNullOrUndefined(siteId, 'siteId', 'sharePointLibraryGet');
        throwIfNullOrUndefined(offset, 'offset', 'sharePointLibraryGet');
        throwIfNullOrUndefined(limit, 'limit', 'sharePointLibraryGet');

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'offset': offset,
            'limit': limit,
        };

        return requestAuthenticated<PageOfRESTSharePointLibrary>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/Sites/{siteId}/Libraries'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{siteId}', encodeURI(siteId)),
            method: 'GET',
            query,
        }, option);
    },

    /**
     * Returns a resource representation of a backed-up SharePoint document library with the specified ID.
     * Get SharePoint Library
     */
    sharePointLibraryGetById: ({ restoreSessionId, siteId, libraryId }: SharePointLibraryApiSharePointLibraryGetByIdRequest, option?: RequestOption): Observable<Optional<RESTSharePointLibrary>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'sharePointLibraryGetById');
        throwIfNullOrUndefined(siteId, 'siteId', 'sharePointLibraryGetById');
        throwIfNullOrUndefined(libraryId, 'libraryId', 'sharePointLibraryGetById');

        return requestAuthenticated<RESTSharePointLibrary>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/Sites/{siteId}/Libraries/{libraryId}'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{siteId}', encodeURI(siteId)).replace('{libraryId}', encodeURI(libraryId)),
            method: 'GET',
        }, option);
    },

    /**
     * Restores a backed-up SharePoint document library with the specified ID to the original location.
     * Restore SharePoint Library to Original Location
     */
    sharePointLibraryRestoreLibraryAction: ({ restoreSessionId, siteId, libraryId, body }: SharePointLibraryApiSharePointLibraryRestoreLibraryActionRequest, option?: RequestOption): Observable<Optional<RESTListRestoreStatistics>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'sharePointLibraryRestoreLibraryAction');
        throwIfNullOrUndefined(siteId, 'siteId', 'sharePointLibraryRestoreLibraryAction');
        throwIfNullOrUndefined(libraryId, 'libraryId', 'sharePointLibraryRestoreLibraryAction');
        throwIfNullOrUndefined(body, 'body', 'sharePointLibraryRestoreLibraryAction');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTListRestoreStatistics>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/Sites/{siteId}/Libraries/{libraryId}/restore'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{siteId}', encodeURI(siteId)).replace('{libraryId}', encodeURI(libraryId)),
            method: 'POST',
            headers,
            body: body,
        }, option);
    },

    /**
     * Restores a backed-up SharePoint document library with the specified ID to another location.
     * Restore SharePoint Library to Another Location
     */
    sharePointLibraryRestoreLibraryActionTo: ({ restoreSessionId, siteId, libraryId, body }: SharePointLibraryApiSharePointLibraryRestoreLibraryActionToRequest, option?: RequestOption): Observable<Optional<RESTListRestoreStatistics>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'sharePointLibraryRestoreLibraryActionTo');
        throwIfNullOrUndefined(siteId, 'siteId', 'sharePointLibraryRestoreLibraryActionTo');
        throwIfNullOrUndefined(libraryId, 'libraryId', 'sharePointLibraryRestoreLibraryActionTo');
        throwIfNullOrUndefined(body, 'body', 'sharePointLibraryRestoreLibraryActionTo');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTListRestoreStatistics>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/Sites/{siteId}/Libraries/{libraryId}/restoreTo'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{siteId}', encodeURI(siteId)).replace('{libraryId}', encodeURI(libraryId)),
            method: 'POST',
            headers,
            body: body,
        }, option);
    },

    /**
     * Saves a backed-up SharePoint document library with the specified ID.  SharePoint document libraries are always saved in a ZIP archive. When you save a SharePoint document library, the request command archives the document library and places the ZIP archive to a temporary folder on the Veeam Backup for Microsoft 365 server. After that, the archive is transferred as application/octet-stream media to the client. To download, read or perform other actions with the octet-stream, use features of programming languages. 
     * Save SharePoint Library
     */
    sharePointLibrarySaveLibraryAction: ({ restoreSessionId, siteId, libraryId }: SharePointLibraryApiSharePointLibrarySaveLibraryActionRequest, option?: RequestOption): Observable<Optional<object>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'sharePointLibrarySaveLibraryAction');
        throwIfNullOrUndefined(siteId, 'siteId', 'sharePointLibrarySaveLibraryAction');
        throwIfNullOrUndefined(libraryId, 'libraryId', 'sharePointLibrarySaveLibraryAction');

        return requestAuthenticated<object>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/Sites/{siteId}/Libraries/{libraryId}/save'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{siteId}', encodeURI(siteId)).replace('{libraryId}', encodeURI(libraryId)),
            method: 'POST',
        }, option);
    },

    /**
     * Searches for SharePoint documents in a backed-up SharePoint library with the specified ID.
     * Search for SharePoint Documents in SharePoint Library
     */
    sharePointLibrarySearchLibraryByOptions: ({ restoreSessionId, siteId, libraryId, offset, limit, body, setId, itemType }: SharePointLibraryApiSharePointLibrarySearchLibraryByOptionsRequest, option?: RequestOption): Observable<Optional<PageOfRESTItemComposed>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'sharePointLibrarySearchLibraryByOptions');
        throwIfNullOrUndefined(siteId, 'siteId', 'sharePointLibrarySearchLibraryByOptions');
        throwIfNullOrUndefined(libraryId, 'libraryId', 'sharePointLibrarySearchLibraryByOptions');
        throwIfNullOrUndefined(offset, 'offset', 'sharePointLibrarySearchLibraryByOptions');
        throwIfNullOrUndefined(limit, 'limit', 'sharePointLibrarySearchLibraryByOptions');
        throwIfNullOrUndefined(body, 'body', 'sharePointLibrarySearchLibraryByOptions');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'offset': offset,
            'limit': limit,
        };

        if (setId != null) { query['setId'] = setId; }
        if (itemType != null) { query['itemType'] = itemType; }

        return requestAuthenticated<PageOfRESTItemComposed>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/Sites/{siteId}/Libraries/{libraryId}/search'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{siteId}', encodeURI(siteId)).replace('{libraryId}', encodeURI(libraryId)),
            method: 'POST',
            headers,
            query,
            body: body,
        }, option);
    },

    /**
     * Sends a backed-up SharePoint document library with the specified ID as an attachment in an email message. <div class=\"note\"><strong>NOTE</strong>  </br> To send items as attachments, you must specify the Veeam Explorer for Microsoft SharePoint email settings. For more information, see [Edit Email Notification Settings](#tag/VespEmailSettings/operation/VespEmailSettings_Update). </div> 
     * Send SharePoint Library
     */
    sharePointLibrarySendLibraryAction: ({ restoreSessionId, siteId, libraryId, body }: SharePointLibraryApiSharePointLibrarySendLibraryActionRequest, option?: RequestOption): Observable<Optional<void>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'sharePointLibrarySendLibraryAction');
        throwIfNullOrUndefined(siteId, 'siteId', 'sharePointLibrarySendLibraryAction');
        throwIfNullOrUndefined(libraryId, 'libraryId', 'sharePointLibrarySendLibraryAction');
        throwIfNullOrUndefined(body, 'body', 'sharePointLibrarySendLibraryAction');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<void>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/Sites/{siteId}/Libraries/{libraryId}/send'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{siteId}', encodeURI(siteId)).replace('{libraryId}', encodeURI(libraryId)),
            method: 'POST',
            headers,
            body: body,
        }, option);
    },

})

/**
 * @export
 * @enum {string}
 */
export enum SharePointLibrarySearchLibraryByOptionsItemTypeEnum {
    All = 'All',
    Folders = 'Folders',
    Items = 'Items'
}

// Copyright © Veeam Software Group GmbH

import { map, shareReplay, tap } from 'rxjs/operators';

import type { Observable } from 'rxjs';
import type { OAuthTokenResponse, RequestOption } from 'api/rxjs';

import { authApi, restorePortalSettingsApi, TokenGrantTypeEnum } from 'api/rxjs';
import resourcesController from 'infrastructure/resources';
import { errorManager } from 'infrastructure/error-management';
import { beginMsalAuth, endMsalAuth, msalLogout, isMsalLoginInitiated, resetMsalInitiated } from './msalAuthorization';
import { AuthState } from './types';
import { isAuthenticated } from './helpers';
import { authStorage } from 'infrastructure/storage';
import { createEvent } from 'infrastructure/event';

import type { AuthController, AuthenticatedController, AuthInfo, LoginResult } from './types';

const events = {
    login: {
        before: createEvent(),
        afterMsalLogin: createEvent<string>(),
        after: createEvent(),
    },
    logout: {
        before: createEvent<string | undefined>(),
        after: createEvent<string | undefined>(),
    },
    renewToken: createEvent<string>(),
};

const sessionKeeper: {
    session?: Observable<OAuthTokenResponse>;
} = {};

const noRegisterError: RequestOption = { registerError: false };

// because API
// eslint-disable-next-line @typescript-eslint/camelcase
const convert = (username: string, { access_token, refresh_token }: OAuthTokenResponse): AuthInfo => ({
    username,
    // eslint-disable-next-line @typescript-eslint/camelcase
    accessToken: access_token,
    // eslint-disable-next-line @typescript-eslint/camelcase
    refreshToken: refresh_token,
});

async function beginLogin(userMail: string): Promise<void> {
    const resources = resourcesController.current.infrastructure.auth;
    try {
        await events.login.before.raise();
        authController.status = AuthState.Authenticating;

        const response = await restorePortalSettingsApi.restorePortalSettingsGet(noRegisterError).toPromise();
        const { isEnabled, applicationId, msalAuthorityUri } = response.getResultOrThrow();

        if (isEnabled === false) throw new Error(resources.selfServicePortalDisabled);
        if (applicationId === undefined) throw new Error(resources.selfServicePortalNotConfigured);

        await beginMsalAuth(applicationId, userMail, msalAuthorityUri);
    } catch (error) {
        authController.status = AuthState.NotAuthenticated;
        throw error;
    }
}

async function endLogin(): Promise<LoginResult> {
    try {
        await events.login.before.raise();
        authController.status = AuthState.Authenticating;

        const { username, clientId, assertion } = await endMsalAuth();
        await events.login.afterMsalLogin.raise(username);

        const response = await authApi
            .token({ grantType: TokenGrantTypeEnum.Operator, clientId, assertion }, noRegisterError)
            .toPromise();
        const vboAuth = response.getResultOrThrow();

        authStorage.save(convert(username, vboAuth));

        authController.status = AuthState.Authenticated;
        (authController as AuthenticatedController).info = {
            accessToken: vboAuth.access_token,
            refreshToken: vboAuth.refresh_token,
            username,
        };
        await events.renewToken.raise(vboAuth.access_token);

        await events.login.after.raise();

        return { username };
    } catch (error) {
        resetMsalInitiated();
        authController.status = AuthState.NotAuthenticated;
        throw error;
    }
}

function refreshTokens(): Observable<OAuthTokenResponse> {
    const resources = resourcesController.current.infrastructure.auth;
    if (authController.status !== 'authenticated') throw new Error(resources.notAuthorizedError);
    if (!sessionKeeper.session) {
        sessionKeeper.session = authApi
            .token(
                { grantType: TokenGrantTypeEnum.RefreshToken, refreshToken: authController.info.refreshToken },
                noRegisterError
            )
            .pipe(
                map(response => response.getResultOrThrow()),
                tap(async(response) => {
                    authStorage.save(convert(authController.info.username, response));
                    authController.info.accessToken = response.access_token;
                    authController.info.refreshToken = response.refresh_token;
                    sessionKeeper.session = undefined;
                    await events.renewToken.raise(response.access_token);
                }),
                shareReplay(),
            );
    }
    return sessionKeeper.session;
}

async function logout(reason?: string, emergency?: boolean): Promise<void> {
    if (!isAuthenticated()) return;
    authController.status = AuthState.Logouting;

    const errorBefore = await events.logout.before.safeRaise(reason);
    if (errorBefore) errorManager.register(errorBefore, { silent: true });

    await authApi.logout().toPromise();

    authController.status = AuthState.NotAuthenticated;
    authStorage.clear();

    const errorAfter = await events.logout.after.safeRaise(reason);
    if (errorAfter) errorManager.register(errorAfter, { silent: true });

    await msalLogout(emergency);
}

const emergencyLogout = (reason?: string): Promise<void> => logout(reason, true);

function getInitialState(): { status: AuthController['status']; info: AuthInfo | undefined; } {
    const info = authStorage.get();
    if (info) return { status: AuthState.Authenticated, info };
    if (isMsalLoginInitiated()) return { status: AuthState.Authenticating, info: undefined };
    return {
        status: AuthState.NotAuthenticated,
        info: undefined,
    };
}

export const authController: AuthController = {
    ...getInitialState(),
    events,
    beginLogin,
    endLogin,
    refreshTokens,
    logout,
    emergencyLogout,
} as AuthController;

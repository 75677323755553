/**
 * The version of the OpenAPI document: v7
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Observable } from 'rxjs';
import { requestAuthenticated, Optional, RequestOption, HttpHeaders, HttpQuery, throwIfNullOrUndefined, encodeURI } from '../runtime';
import {
    PageOfRESTExchangeItemsComposed,
    PageOfRESTExchangeMailbox,
    RESTBulkRestoreStatistics,
    RESTExceptionInfo,
    RESTExchangeMailbox,
    RESTExportFolderToPst,
    RESTFoldersRestoreStatistics,
    RESTRestoreToDifferentLocation,
    RESTRestoreToOriginalLocation,
    RESTSearchOptions,
    RestBulkRestoreOptions,
} from '../models';

export interface ExchangeMailboxApiExchangeMailboxExportMailboxToPstActionRequest {
    restoreSessionId: string;
    mailboxId: string;
    body: RESTExportFolderToPst;
}

export interface ExchangeMailboxApiExchangeMailboxGetRequest {
    restoreSessionId: string;
    offset: number;
    limit: number;
}

export interface ExchangeMailboxApiExchangeMailboxGetByIdRequest {
    restoreSessionId: string;
    mailboxId: string;
}

export interface ExchangeMailboxApiExchangeMailboxRestoreMailboxActionRequest {
    restoreSessionId: string;
    mailboxId: string;
    body: RESTRestoreToOriginalLocation;
}

export interface ExchangeMailboxApiExchangeMailboxRestoreMailboxToActionRequest {
    restoreSessionId: string;
    mailboxId: string;
    body: RESTRestoreToDifferentLocation;
}

export interface ExchangeMailboxApiExchangeMailboxRestoreMailboxesActionRequest {
    restoreSessionId: string;
    body: RestBulkRestoreOptions;
}

export interface ExchangeMailboxApiExchangeMailboxSearchByIdByOptionsRequest {
    restoreSessionId: string;
    mailboxId: string;
    offset: number;
    limit: number;
    body: RESTSearchOptions;
    setId?: string;
}

export interface ExchangeMailboxApiExchangeMailboxSearchByOptionsRequest {
    restoreSessionId: string;
    offset: number;
    limit: number;
    body: RESTSearchOptions;
    setId?: string;
}

/**
 * no description
 */
export const exchangeMailboxApi = ({

    /**
     * Exports backed-up data from a mailbox with the specified ID to a PST file. <div class=\"important\"><strong> IMPORTANT</strong> </br> To export data to PST (Personal Storage Table) files, you must have a 64-bit version of Microsoft Outlook 2016, Microsoft Outlook 2013 or Microsoft Outlook 2010 installed on a computer running restore sessions. </div> The request command will look for a specified keyword in data inside the organization mailbox. The backed-up data with the specified keyword then will be exported to a PST file and placed to a temporary folder on the Veeam Backup for Microsoft 365 server. After that, the PST file will be transferred as application/octet-stream media to the client. To download, read, convert to PST or perform other actions with the octet-stream, use features of programming languages.  If the size of the exported data exceeds the limit specified for the PST file, Veeam Backup for Microsoft 365 splits the PST file into separate files and exports these files to an archive file of the ZIP format. For example, you specified 1 GB as the PST file size limit. The size of the exported data is 1.5 GB. In this case, Veeam Backup for Microsoft 365 will export data to a ZIP archive. The archive will contain two PST files: one PST file of the 1 GB size and another PST file of the 0.5 GB size. 
     * Export Mailbox Data
     */
    exchangeMailboxExportMailboxToPstAction: ({ restoreSessionId, mailboxId, body }: ExchangeMailboxApiExchangeMailboxExportMailboxToPstActionRequest, option?: RequestOption): Observable<Optional<object>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'exchangeMailboxExportMailboxToPstAction');
        throwIfNullOrUndefined(mailboxId, 'mailboxId', 'exchangeMailboxExportMailboxToPstAction');
        throwIfNullOrUndefined(body, 'body', 'exchangeMailboxExportMailboxToPstAction');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<object>({
            url: '/v7/RestoreSessions/{restoreSessionId}/organization/mailboxes/{mailboxId}/exportToPst'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{mailboxId}', encodeURI(mailboxId)),
            method: 'POST',
            headers,
            body: body,
        }, option);
    },

    /**
     * Returns a collection of organization mailboxes to explore and restore data.
     * Get Mailboxes
     */
    exchangeMailboxGet: ({ restoreSessionId, offset, limit }: ExchangeMailboxApiExchangeMailboxGetRequest, option?: RequestOption): Observable<Optional<PageOfRESTExchangeMailbox>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'exchangeMailboxGet');
        throwIfNullOrUndefined(offset, 'offset', 'exchangeMailboxGet');
        throwIfNullOrUndefined(limit, 'limit', 'exchangeMailboxGet');

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'offset': offset,
            'limit': limit,
        };

        return requestAuthenticated<PageOfRESTExchangeMailbox>({
            url: '/v7/RestoreSessions/{restoreSessionId}/organization/mailboxes'.replace('{restoreSessionId}', encodeURI(restoreSessionId)),
            method: 'GET',
            query,
        }, option);
    },

    /**
     * Returns a resource representation of an organization mailbox with the specified ID to explore and restore data.
     * Get Mailbox
     */
    exchangeMailboxGetById: ({ restoreSessionId, mailboxId }: ExchangeMailboxApiExchangeMailboxGetByIdRequest, option?: RequestOption): Observable<Optional<RESTExchangeMailbox>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'exchangeMailboxGetById');
        throwIfNullOrUndefined(mailboxId, 'mailboxId', 'exchangeMailboxGetById');

        return requestAuthenticated<RESTExchangeMailbox>({
            url: '/v7/RestoreSessions/{restoreSessionId}/organization/mailboxes/{mailboxId}'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{mailboxId}', encodeURI(mailboxId)),
            method: 'GET',
        }, option);
    },

    /**
     * Restores backed-up data from a mailbox with the specified ID to the original Exchange server.
     * Restore Mailbox Data to Original Exchange Server
     */
    exchangeMailboxRestoreMailboxAction: ({ restoreSessionId, mailboxId, body }: ExchangeMailboxApiExchangeMailboxRestoreMailboxActionRequest, option?: RequestOption): Observable<Optional<RESTFoldersRestoreStatistics>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'exchangeMailboxRestoreMailboxAction');
        throwIfNullOrUndefined(mailboxId, 'mailboxId', 'exchangeMailboxRestoreMailboxAction');
        throwIfNullOrUndefined(body, 'body', 'exchangeMailboxRestoreMailboxAction');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTFoldersRestoreStatistics>({
            url: '/v7/RestoreSessions/{restoreSessionId}/organization/mailboxes/{mailboxId}/restore'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{mailboxId}', encodeURI(mailboxId)),
            method: 'POST',
            headers,
            body: body,
        }, option);
    },

    /**
     * Restores backed-up data from a mailbox with the specified ID to another Exchange server.
     * Restore Mailbox Data to Another Exchange Server
     */
    exchangeMailboxRestoreMailboxToAction: ({ restoreSessionId, mailboxId, body }: ExchangeMailboxApiExchangeMailboxRestoreMailboxToActionRequest, option?: RequestOption): Observable<Optional<RESTFoldersRestoreStatistics>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'exchangeMailboxRestoreMailboxToAction');
        throwIfNullOrUndefined(mailboxId, 'mailboxId', 'exchangeMailboxRestoreMailboxToAction');
        throwIfNullOrUndefined(body, 'body', 'exchangeMailboxRestoreMailboxToAction');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTFoldersRestoreStatistics>({
            url: '/v7/RestoreSessions/{restoreSessionId}/organization/mailboxes/{mailboxId}/restoreTo'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{mailboxId}', encodeURI(mailboxId)),
            method: 'POST',
            headers,
            body: body,
        }, option);
    },

    /**
     * Performs a bulk restore of backed-up organization mailbox data.
     * Bulk Restore of Mailbox Data
     */
    exchangeMailboxRestoreMailboxesAction: ({ restoreSessionId, body }: ExchangeMailboxApiExchangeMailboxRestoreMailboxesActionRequest, option?: RequestOption): Observable<Optional<RESTBulkRestoreStatistics>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'exchangeMailboxRestoreMailboxesAction');
        throwIfNullOrUndefined(body, 'body', 'exchangeMailboxRestoreMailboxesAction');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTBulkRestoreStatistics>({
            url: '/v7/RestoreSessions/{restoreSessionId}/organization/mailboxes/restore'.replace('{restoreSessionId}', encodeURI(restoreSessionId)),
            method: 'POST',
            headers,
            body: body,
        }, option);
    },

    /**
     * Searches for items in a mailbox with the specified ID.
     * Search for Exchange Items in Mailbox
     */
    exchangeMailboxSearchByIdByOptions: ({ restoreSessionId, mailboxId, offset, limit, body, setId }: ExchangeMailboxApiExchangeMailboxSearchByIdByOptionsRequest, option?: RequestOption): Observable<Optional<PageOfRESTExchangeItemsComposed>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'exchangeMailboxSearchByIdByOptions');
        throwIfNullOrUndefined(mailboxId, 'mailboxId', 'exchangeMailboxSearchByIdByOptions');
        throwIfNullOrUndefined(offset, 'offset', 'exchangeMailboxSearchByIdByOptions');
        throwIfNullOrUndefined(limit, 'limit', 'exchangeMailboxSearchByIdByOptions');
        throwIfNullOrUndefined(body, 'body', 'exchangeMailboxSearchByIdByOptions');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'offset': offset,
            'limit': limit,
        };

        if (setId != null) { query['setId'] = setId; }

        return requestAuthenticated<PageOfRESTExchangeItemsComposed>({
            url: '/v7/RestoreSessions/{restoreSessionId}/organization/mailboxes/{mailboxId}/search'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{mailboxId}', encodeURI(mailboxId)),
            method: 'POST',
            headers,
            query,
            body: body,
        }, option);
    },

    /**
     * Searches for items in backed-up organization mailboxes.
     * Search for Exchange Items in Mailboxes
     */
    exchangeMailboxSearchByOptions: ({ restoreSessionId, offset, limit, body, setId }: ExchangeMailboxApiExchangeMailboxSearchByOptionsRequest, option?: RequestOption): Observable<Optional<PageOfRESTExchangeItemsComposed>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'exchangeMailboxSearchByOptions');
        throwIfNullOrUndefined(offset, 'offset', 'exchangeMailboxSearchByOptions');
        throwIfNullOrUndefined(limit, 'limit', 'exchangeMailboxSearchByOptions');
        throwIfNullOrUndefined(body, 'body', 'exchangeMailboxSearchByOptions');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'offset': offset,
            'limit': limit,
        };

        if (setId != null) { query['setId'] = setId; }

        return requestAuthenticated<PageOfRESTExchangeItemsComposed>({
            url: '/v7/RestoreSessions/{restoreSessionId}/organization/mailboxes/search'.replace('{restoreSessionId}', encodeURI(restoreSessionId)),
            method: 'POST',
            headers,
            query,
            body: body,
        }, option);
    },

})

/**
 * The version of the OpenAPI document: v7
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Observable } from 'rxjs';
import { requestAuthenticated, Optional, RequestOption, HttpHeaders, HttpQuery, throwIfNullOrUndefined, encodeURI } from '../runtime';
import {
    PageOfRESTItemComposed,
    PageOfRESTOneDrive,
    RESTBulkRestoreConfig,
    RESTCopyToFolder,
    RESTExceptionInfo,
    RESTItemRestoreStatistics,
    RESTOneDrive,
    RESTRestoreToOriginal,
    RESTSharePointBulkRestoreStatistics,
    RESTSharePointSendAsMsgOptions,
    RESTVESPSearchOptions,
} from '../models';

export interface OneDriveApiOneDriveCopyToOneDriveActionRequest {
    restoreSessionId: string;
    oneDriveId: string;
    body: RESTCopyToFolder;
}

export interface OneDriveApiOneDriveGetRequest {
    restoreSessionId: string;
    offset: number;
    limit: number;
}

export interface OneDriveApiOneDriveGetByIdRequest {
    restoreSessionId: string;
    oneDriveId: string;
}

export interface OneDriveApiOneDriveRestoreOneDriveActionRequest {
    restoreSessionId: string;
    oneDriveId: string;
    body: RESTRestoreToOriginal;
}

export interface OneDriveApiOneDriveRestoreOneDrivesActionRequest {
    restoreSessionId: string;
    body: RESTBulkRestoreConfig;
}

export interface OneDriveApiOneDriveSaveOneDriveActionRequest {
    restoreSessionId: string;
    oneDriveId: string;
}

export interface OneDriveApiOneDriveSearchOneDriveByOptionsRequest {
    restoreSessionId: string;
    oneDriveId: string;
    offset: number;
    limit: number;
    body: RESTVESPSearchOptions;
    setId?: string;
    itemType?: OneDriveSearchOneDriveByOptionsItemTypeEnum;
}

export interface OneDriveApiOneDriveSearchOneDrivesByOptionsRequest {
    restoreSessionId: string;
    offset: number;
    limit: number;
    body: RESTVESPSearchOptions;
    setId?: string;
    itemType?: OneDriveSearchOneDrivesByOptionsItemTypeEnum;
}

export interface OneDriveApiOneDriveSendOneDriveActionRequest {
    restoreSessionId: string;
    oneDriveId: string;
    body: RESTSharePointSendAsMsgOptions;
}

/**
 * no description
 */
export const oneDriveApi = ({

    /**
     * Copies backed-up data of the specified OneDrive to another location.
     * Copy OneDrive Data
     */
    oneDriveCopyToOneDriveAction: ({ restoreSessionId, oneDriveId, body }: OneDriveApiOneDriveCopyToOneDriveActionRequest, option?: RequestOption): Observable<Optional<RESTItemRestoreStatistics>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'oneDriveCopyToOneDriveAction');
        throwIfNullOrUndefined(oneDriveId, 'oneDriveId', 'oneDriveCopyToOneDriveAction');
        throwIfNullOrUndefined(body, 'body', 'oneDriveCopyToOneDriveAction');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTItemRestoreStatistics>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/OneDrives/{oneDriveId}/copyTo'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{oneDriveId}', encodeURI(oneDriveId)),
            method: 'POST',
            headers,
            body: body,
        }, option);
    },

    /**
     * Returns a collection of OneDrives to explore and restore OneDrive data.
     * Get OneDrives
     */
    oneDriveGet: ({ restoreSessionId, offset, limit }: OneDriveApiOneDriveGetRequest, option?: RequestOption): Observable<Optional<PageOfRESTOneDrive>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'oneDriveGet');
        throwIfNullOrUndefined(offset, 'offset', 'oneDriveGet');
        throwIfNullOrUndefined(limit, 'limit', 'oneDriveGet');

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'offset': offset,
            'limit': limit,
        };

        return requestAuthenticated<PageOfRESTOneDrive>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/OneDrives'.replace('{restoreSessionId}', encodeURI(restoreSessionId)),
            method: 'GET',
            query,
        }, option);
    },

    /**
     * Returns a resource representation of OneDrive with the specified ID to explore and restore data.
     * Get OneDrive
     */
    oneDriveGetById: ({ restoreSessionId, oneDriveId }: OneDriveApiOneDriveGetByIdRequest, option?: RequestOption): Observable<Optional<RESTOneDrive>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'oneDriveGetById');
        throwIfNullOrUndefined(oneDriveId, 'oneDriveId', 'oneDriveGetById');

        return requestAuthenticated<RESTOneDrive>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/OneDrives/{oneDriveId}'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{oneDriveId}', encodeURI(oneDriveId)),
            method: 'GET',
        }, option);
    },

    /**
     * Restores backed-up data of OneDrive with the specified ID to its original location.
     * Restore OneDrive Data
     */
    oneDriveRestoreOneDriveAction: ({ restoreSessionId, oneDriveId, body }: OneDriveApiOneDriveRestoreOneDriveActionRequest, option?: RequestOption): Observable<Optional<RESTItemRestoreStatistics>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'oneDriveRestoreOneDriveAction');
        throwIfNullOrUndefined(oneDriveId, 'oneDriveId', 'oneDriveRestoreOneDriveAction');
        throwIfNullOrUndefined(body, 'body', 'oneDriveRestoreOneDriveAction');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTItemRestoreStatistics>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/OneDrives/{oneDriveId}/restore'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{oneDriveId}', encodeURI(oneDriveId)),
            method: 'POST',
            headers,
            body: body,
        }, option);
    },

    /**
     * Performs a bulk restore of backed-up OneDrives.
     * Bulk Restore of OneDrives
     */
    oneDriveRestoreOneDrivesAction: ({ restoreSessionId, body }: OneDriveApiOneDriveRestoreOneDrivesActionRequest, option?: RequestOption): Observable<Optional<RESTSharePointBulkRestoreStatistics>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'oneDriveRestoreOneDrivesAction');
        throwIfNullOrUndefined(body, 'body', 'oneDriveRestoreOneDrivesAction');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTSharePointBulkRestoreStatistics>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/OneDrives/restore'.replace('{restoreSessionId}', encodeURI(restoreSessionId)),
            method: 'POST',
            headers,
            body: body,
        }, option);
    },

    /**
     * Saves backed-up data of OneDrive with the specified ID.  OneDrive items are always saved in a ZIP archive. When you save OneDrive data, the request command compresses the data and places the ZIP archive to a temporary folder on the Veeam Backup for Microsoft 365 server. After that, the archive is transferred as application/octet-stream media to the client. To download, read or perform other actions with the octet-stream, use features of programming languages. 
     * Save OneDrive Data
     */
    oneDriveSaveOneDriveAction: ({ restoreSessionId, oneDriveId }: OneDriveApiOneDriveSaveOneDriveActionRequest, option?: RequestOption): Observable<Optional<object>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'oneDriveSaveOneDriveAction');
        throwIfNullOrUndefined(oneDriveId, 'oneDriveId', 'oneDriveSaveOneDriveAction');

        return requestAuthenticated<object>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/OneDrives/{oneDriveId}/save'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{oneDriveId}', encodeURI(oneDriveId)),
            method: 'POST',
        }, option);
    },

    /**
     * Searches for OneDrive items in backed-up OneDrive with the specified ID.
     * Search for OneDrive Items in OneDrive
     */
    oneDriveSearchOneDriveByOptions: ({ restoreSessionId, oneDriveId, offset, limit, body, setId, itemType }: OneDriveApiOneDriveSearchOneDriveByOptionsRequest, option?: RequestOption): Observable<Optional<PageOfRESTItemComposed>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'oneDriveSearchOneDriveByOptions');
        throwIfNullOrUndefined(oneDriveId, 'oneDriveId', 'oneDriveSearchOneDriveByOptions');
        throwIfNullOrUndefined(offset, 'offset', 'oneDriveSearchOneDriveByOptions');
        throwIfNullOrUndefined(limit, 'limit', 'oneDriveSearchOneDriveByOptions');
        throwIfNullOrUndefined(body, 'body', 'oneDriveSearchOneDriveByOptions');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'offset': offset,
            'limit': limit,
        };

        if (setId != null) { query['setId'] = setId; }
        if (itemType != null) { query['itemType'] = itemType; }

        return requestAuthenticated<PageOfRESTItemComposed>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/OneDrives/{oneDriveId}/search'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{oneDriveId}', encodeURI(oneDriveId)),
            method: 'POST',
            headers,
            query,
            body: body,
        }, option);
    },

    /**
     * Searches for OneDrive items in backed-up organization OneDrives.
     * Search for OneDrive Items in OneDrives
     */
    oneDriveSearchOneDrivesByOptions: ({ restoreSessionId, offset, limit, body, setId, itemType }: OneDriveApiOneDriveSearchOneDrivesByOptionsRequest, option?: RequestOption): Observable<Optional<PageOfRESTItemComposed>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'oneDriveSearchOneDrivesByOptions');
        throwIfNullOrUndefined(offset, 'offset', 'oneDriveSearchOneDrivesByOptions');
        throwIfNullOrUndefined(limit, 'limit', 'oneDriveSearchOneDrivesByOptions');
        throwIfNullOrUndefined(body, 'body', 'oneDriveSearchOneDrivesByOptions');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'offset': offset,
            'limit': limit,
        };

        if (setId != null) { query['setId'] = setId; }
        if (itemType != null) { query['itemType'] = itemType; }

        return requestAuthenticated<PageOfRESTItemComposed>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/OneDrives/search'.replace('{restoreSessionId}', encodeURI(restoreSessionId)),
            method: 'POST',
            headers,
            query,
            body: body,
        }, option);
    },

    /**
     * Sends backed-up data of OneDrive with the specified ID as attachments in an email message. <div class=\"note\"><strong>NOTE</strong>  </br> To send items as attachments, you must specify the Veeam Explorer for Microsoft OneDrive for Business email settings. For more information, see [Edit Email Notification Settings](#tag/VeodEmailSettings/operation/VeodEmailSettings_Update). </div> 
     * Send OneDrive Data
     */
    oneDriveSendOneDriveAction: ({ restoreSessionId, oneDriveId, body }: OneDriveApiOneDriveSendOneDriveActionRequest, option?: RequestOption): Observable<Optional<void>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'oneDriveSendOneDriveAction');
        throwIfNullOrUndefined(oneDriveId, 'oneDriveId', 'oneDriveSendOneDriveAction');
        throwIfNullOrUndefined(body, 'body', 'oneDriveSendOneDriveAction');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<void>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/OneDrives/{oneDriveId}/send'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{oneDriveId}', encodeURI(oneDriveId)),
            method: 'POST',
            headers,
            body: body,
        }, option);
    },

})

/**
 * @export
 * @enum {string}
 */
export enum OneDriveSearchOneDriveByOptionsItemTypeEnum {
    All = 'All',
    Folders = 'Folders',
    Documents = 'Documents'
}
/**
 * @export
 * @enum {string}
 */
export enum OneDriveSearchOneDrivesByOptionsItemTypeEnum {
    All = 'All',
    Folders = 'Folders',
    Documents = 'Documents'
}

// Copyright © Veeam Software Group GmbH

import type { RESTOperatorInfo, RESTRbacLoggedInUser, ResultKeeper } from 'api/rxjs';

import { organizationRbacApi } from 'api/rxjs';
import { errorManager } from 'infrastructure/error-management';
import { getRbacScopes } from './getRbacScopes';
import { RbacScopeType, defaultRbacScopeId } from './models';
import resourcesController from 'infrastructure/resources';
import { authController } from 'infrastructure/auth';
import { rbacStorage } from 'infrastructure/storage';
import { createEvent } from 'infrastructure/event';

import type { RbacInfo, RbacService } from './types';
import type { RbacScope, RbacScopeId } from './models';

const onScopeChanged = createEvent<RbacScope>();

export const getDefaultScope = (id?: RbacScopeId, isOperator?: boolean): RbacScope => ({
    type: RbacScopeType.User,
    id: id || defaultRbacScopeId,
    title: '',
    details: '',
    item: undefined,
    has: {
        exchange: true,
        oneDrive: true,
        sharePoint: true,
        teams: false,
    },
});

const getInitialInfo = (id?: RbacScopeId): RbacInfo =>
    rbacStorage.get() || {
        isOperator: undefined,
        operatorId: id || defaultRbacScopeId,
        scope: getDefaultScope(id),
    };

const resetToInitialInfo = (id?: RbacScopeId): void => {
    info.isOperator = undefined;
    info.operatorId = id || defaultRbacScopeId;
    info.scope = getDefaultScope(id);
};

const changeScope = async(newScope: RbacScope): Promise<void> => {
    info.scope = newScope;
    rbacStorage.save(info);
    await onScopeChanged.raise(newScope);
};

const info = getInitialInfo();

export const rbacService: RbacService = {
    info,
    onScopeChanged,
    getRbacScopes: req => getRbacScopes(req, info.scope.id, Boolean(info.isOperator)),
    changeScope,
    fetchOperatorInfo,
};

authController.events.login.after.subscribe(fetchUserInfo);
authController.events.logout.after.subscribe(rbacStorage.clear);
authController.events.logout.after.subscribe(resetToInitialInfo);

async function fetchUserInfo(): Promise<void> {
    const opt = await organizationRbacApi.organizationRbacGetLoggedInUser().toPromise();

    if (opt.isError) {
        errorManager.register(resourcesController.current.services.rbac.RequestUserInfoIsFailed);
        resetToInitialInfo();
        return;
    }

    const newOperatorId = (opt as ResultKeeper<RESTRbacLoggedInUser>).data.id as RbacScopeId;
    if (info.operatorId !== newOperatorId) {
        resetToInitialInfo(newOperatorId);
    }
    await onScopeChanged.raise(info.scope);
}

export async function fetchOperatorInfo(): Promise<void | RbacInfo> {
    const opt = await organizationRbacApi.organizationRbacGetLoggedInUserOperatorInfo().toPromise();

    if (opt.isError) return;

    info.isOperator = (opt as ResultKeeper<RESTOperatorInfo>).data.isOperator;
    info.scope = getDefaultScope(info.operatorId, (opt as ResultKeeper<RESTOperatorInfo>).data.isOperator);

    rbacStorage.save(info);

    return rbacStorage.get();
}

/**
 * The version of the OpenAPI document: v7
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Observable } from 'rxjs';
import { requestAuthenticated, Optional, RequestOption, HttpHeaders, HttpQuery, throwIfNullOrUndefined, encodeURI } from '../runtime';
import {
    PageOfRESTItemComposed,
    PageOfRESTOneDriveDocument,
    RESTCopyToDocument,
    RESTExceptionInfo,
    RESTItemRestoreStatistics,
    RESTOneDriveDocument,
    RESTOperatorRestoreSessionResponse,
    RESTOperatorRestoreToOriginalOneDriveDocuments,
    RESTRestoreToOriginal,
    RESTRestoreToOriginalDocuments,
    RESTSaveDocumentsOptions,
    RESTSaveOneDriveDocumentOptions,
    RESTSendAsMsgOptionsV6,
    RESTSendDocumentsAsMsgOptions,
    RestCopyToDocuments,
} from '../models';

export interface OneDriveDocumentApiOneDriveDocumentCopyToDocumentActionRequest {
    restoreSessionId: string;
    oneDriveId: string;
    documentId: string;
    body: RESTCopyToDocument;
}

export interface OneDriveDocumentApiOneDriveDocumentCopyToDocumentActionByVersionIdRequest {
    restoreSessionId: string;
    oneDriveId: string;
    documentId: string;
    versionId: number;
    body: RESTCopyToDocument;
}

export interface OneDriveDocumentApiOneDriveDocumentCopyToDocumentsActionRequest {
    restoreSessionId: string;
    oneDriveId: string;
    body: RestCopyToDocuments;
}

export interface OneDriveDocumentApiOneDriveDocumentGetRequest {
    restoreSessionId: string;
    oneDriveId: string;
    offset: number;
    limit: number;
    parentId?: string;
    includeFolders?: boolean;
}

export interface OneDriveDocumentApiOneDriveDocumentGetByIdRequest {
    restoreSessionId: string;
    oneDriveId: string;
    documentId: string;
}

export interface OneDriveDocumentApiOneDriveDocumentGetByIdByVersionIdRequest {
    restoreSessionId: string;
    oneDriveId: string;
    documentId: string;
    versionId: number;
}

export interface OneDriveDocumentApiOneDriveDocumentGetVersionsRequest {
    restoreSessionId: string;
    oneDriveId: string;
    documentId: string;
    offset: number;
    limit: number;
}

export interface OneDriveDocumentApiOneDriveDocumentOperatorRestoreRequest {
    restoreSessionId: string;
    oneDriveId: string;
    body: RESTOperatorRestoreToOriginalOneDriveDocuments;
}

export interface OneDriveDocumentApiOneDriveDocumentRestoreDocumentActionRequest {
    restoreSessionId: string;
    oneDriveId: string;
    documentId: string;
    body: RESTRestoreToOriginal;
}

export interface OneDriveDocumentApiOneDriveDocumentRestoreDocumentActionByVersionIdRequest {
    restoreSessionId: string;
    oneDriveId: string;
    documentId: string;
    versionId: number;
    body: RESTRestoreToOriginal;
}

export interface OneDriveDocumentApiOneDriveDocumentRestoreDocumentsActionRequest {
    restoreSessionId: string;
    oneDriveId: string;
    body: RESTRestoreToOriginalDocuments;
}

export interface OneDriveDocumentApiOneDriveDocumentSaveDocumentActionRequest {
    restoreSessionId: string;
    oneDriveId: string;
    documentId: string;
    body: RESTSaveOneDriveDocumentOptions;
}

export interface OneDriveDocumentApiOneDriveDocumentSaveDocumentActionByVersionIdRequest {
    restoreSessionId: string;
    oneDriveId: string;
    documentId: string;
    versionId: number;
    body: RESTSaveOneDriveDocumentOptions;
}

export interface OneDriveDocumentApiOneDriveDocumentSaveDocumentsActionRequest {
    restoreSessionId: string;
    oneDriveId: string;
    body: RESTSaveDocumentsOptions;
}

export interface OneDriveDocumentApiOneDriveDocumentSendDocumentActionRequest {
    restoreSessionId: string;
    oneDriveId: string;
    documentId: string;
    body: RESTSendAsMsgOptionsV6;
}

export interface OneDriveDocumentApiOneDriveDocumentSendDocumentActionByVersionIdRequest {
    restoreSessionId: string;
    oneDriveId: string;
    documentId: string;
    versionId: number;
    body: RESTSendAsMsgOptionsV6;
}

export interface OneDriveDocumentApiOneDriveDocumentSendDocumentsActionRequest {
    restoreSessionId: string;
    oneDriveId: string;
    body: RESTSendDocumentsAsMsgOptions;
}

/**
 * no description
 */
export const oneDriveDocumentApi = ({

    /**
     * Copies a backed-up OneDrive document with the specified ID to another location in Microsoft OneDrive.
     * Copy OneDrive Document
     */
    oneDriveDocumentCopyToDocumentAction: ({ restoreSessionId, oneDriveId, documentId, body }: OneDriveDocumentApiOneDriveDocumentCopyToDocumentActionRequest, option?: RequestOption): Observable<Optional<RESTItemRestoreStatistics>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'oneDriveDocumentCopyToDocumentAction');
        throwIfNullOrUndefined(oneDriveId, 'oneDriveId', 'oneDriveDocumentCopyToDocumentAction');
        throwIfNullOrUndefined(documentId, 'documentId', 'oneDriveDocumentCopyToDocumentAction');
        throwIfNullOrUndefined(body, 'body', 'oneDriveDocumentCopyToDocumentAction');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTItemRestoreStatistics>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/OneDrives/{oneDriveId}/Documents/{documentId}/copyTo'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{oneDriveId}', encodeURI(oneDriveId)).replace('{documentId}', encodeURI(documentId)),
            method: 'POST',
            headers,
            body: body,
        }, option);
    },

    /**
     * Copies a specific version of a backed-up OneDrive document to another location in Microsoft OneDrive.
     * Copy Version of OneDrive Document
     */
    oneDriveDocumentCopyToDocumentActionByVersionId: ({ restoreSessionId, oneDriveId, documentId, versionId, body }: OneDriveDocumentApiOneDriveDocumentCopyToDocumentActionByVersionIdRequest, option?: RequestOption): Observable<Optional<RESTItemRestoreStatistics>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'oneDriveDocumentCopyToDocumentActionByVersionId');
        throwIfNullOrUndefined(oneDriveId, 'oneDriveId', 'oneDriveDocumentCopyToDocumentActionByVersionId');
        throwIfNullOrUndefined(documentId, 'documentId', 'oneDriveDocumentCopyToDocumentActionByVersionId');
        throwIfNullOrUndefined(versionId, 'versionId', 'oneDriveDocumentCopyToDocumentActionByVersionId');
        throwIfNullOrUndefined(body, 'body', 'oneDriveDocumentCopyToDocumentActionByVersionId');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTItemRestoreStatistics>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/OneDrives/{oneDriveId}/Documents/{documentId}/Versions/{versionId}/copyTo'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{oneDriveId}', encodeURI(oneDriveId)).replace('{documentId}', encodeURI(documentId)).replace('{versionId}', encodeURI(versionId)),
            method: 'POST',
            headers,
            body: body,
        }, option);
    },

    /**
     * Copies backed-up OneDrive documents to another location in Microsoft OneDrive.
     * Copy OneDrive Documents
     */
    oneDriveDocumentCopyToDocumentsAction: ({ restoreSessionId, oneDriveId, body }: OneDriveDocumentApiOneDriveDocumentCopyToDocumentsActionRequest, option?: RequestOption): Observable<Optional<RESTItemRestoreStatistics>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'oneDriveDocumentCopyToDocumentsAction');
        throwIfNullOrUndefined(oneDriveId, 'oneDriveId', 'oneDriveDocumentCopyToDocumentsAction');
        throwIfNullOrUndefined(body, 'body', 'oneDriveDocumentCopyToDocumentsAction');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTItemRestoreStatistics>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/OneDrives/{oneDriveId}/Documents/copyTo'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{oneDriveId}', encodeURI(oneDriveId)),
            method: 'POST',
            headers,
            body: body,
        }, option);
    },

    /**
     * Returns a collection of OneDrive documents to explore and restore.
     * Get OneDrive Documents
     */
    oneDriveDocumentGet: ({ restoreSessionId, oneDriveId, offset, limit, parentId, includeFolders }: OneDriveDocumentApiOneDriveDocumentGetRequest, option?: RequestOption): Observable<Optional<PageOfRESTItemComposed>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'oneDriveDocumentGet');
        throwIfNullOrUndefined(oneDriveId, 'oneDriveId', 'oneDriveDocumentGet');
        throwIfNullOrUndefined(offset, 'offset', 'oneDriveDocumentGet');
        throwIfNullOrUndefined(limit, 'limit', 'oneDriveDocumentGet');

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'offset': offset,
            'limit': limit,
        };

        if (parentId != null) { query['parentId'] = parentId; }
        if (includeFolders != null) { query['includeFolders'] = includeFolders; }

        return requestAuthenticated<PageOfRESTItemComposed>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/OneDrives/{oneDriveId}/Documents'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{oneDriveId}', encodeURI(oneDriveId)),
            method: 'GET',
            query,
        }, option);
    },

    /**
     * Returns a resource representation of a backed-up OneDrive document with the specified ID.
     * Get OneDrive Document
     */
    oneDriveDocumentGetById: ({ restoreSessionId, oneDriveId, documentId }: OneDriveDocumentApiOneDriveDocumentGetByIdRequest, option?: RequestOption): Observable<Optional<RESTOneDriveDocument>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'oneDriveDocumentGetById');
        throwIfNullOrUndefined(oneDriveId, 'oneDriveId', 'oneDriveDocumentGetById');
        throwIfNullOrUndefined(documentId, 'documentId', 'oneDriveDocumentGetById');

        return requestAuthenticated<RESTOneDriveDocument>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/OneDrives/{oneDriveId}/Documents/{documentId}'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{oneDriveId}', encodeURI(oneDriveId)).replace('{documentId}', encodeURI(documentId)),
            method: 'GET',
        }, option);
    },

    /**
     * Returns a resource representation of a specific version of a backed-up OneDrive document with the specified ID.
     * Get Specific Version of OneDrive Document
     */
    oneDriveDocumentGetByIdByVersionId: ({ restoreSessionId, oneDriveId, documentId, versionId }: OneDriveDocumentApiOneDriveDocumentGetByIdByVersionIdRequest, option?: RequestOption): Observable<Optional<RESTOneDriveDocument>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'oneDriveDocumentGetByIdByVersionId');
        throwIfNullOrUndefined(oneDriveId, 'oneDriveId', 'oneDriveDocumentGetByIdByVersionId');
        throwIfNullOrUndefined(documentId, 'documentId', 'oneDriveDocumentGetByIdByVersionId');
        throwIfNullOrUndefined(versionId, 'versionId', 'oneDriveDocumentGetByIdByVersionId');

        return requestAuthenticated<RESTOneDriveDocument>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/OneDrives/{oneDriveId}/Documents/{documentId}/Versions/{versionId}'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{oneDriveId}', encodeURI(oneDriveId)).replace('{documentId}', encodeURI(documentId)).replace('{versionId}', encodeURI(versionId)),
            method: 'GET',
        }, option);
    },

    /**
     * Returns a collection of versions of a backed-up OneDrive document with the specified ID.  When you get OneDrive document versions, the server returns information about previous versions of the document. To get the latest version, use either [Get OneDrive Document](#tag/OneDriveDocument/operation/OneDriveDocument_GetById) or [Get Specific Version of OneDrive Document](#tag/OneDriveDocument/operation/OneDriveDocument_GetByIdByVersionId). 
     * Get All Versions of OneDrive Document
     */
    oneDriveDocumentGetVersions: ({ restoreSessionId, oneDriveId, documentId, offset, limit }: OneDriveDocumentApiOneDriveDocumentGetVersionsRequest, option?: RequestOption): Observable<Optional<PageOfRESTOneDriveDocument>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'oneDriveDocumentGetVersions');
        throwIfNullOrUndefined(oneDriveId, 'oneDriveId', 'oneDriveDocumentGetVersions');
        throwIfNullOrUndefined(documentId, 'documentId', 'oneDriveDocumentGetVersions');
        throwIfNullOrUndefined(offset, 'offset', 'oneDriveDocumentGetVersions');
        throwIfNullOrUndefined(limit, 'limit', 'oneDriveDocumentGetVersions');

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'offset': offset,
            'limit': limit,
        };

        return requestAuthenticated<PageOfRESTOneDriveDocument>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/OneDrives/{oneDriveId}/Documents/{documentId}/Versions'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{oneDriveId}', encodeURI(oneDriveId)).replace('{documentId}', encodeURI(documentId)),
            method: 'GET',
            query,
        }, option);
    },

    /**
     * Restores backed-up OneDrive documents using Restore Portal. For more information about Restore Portal, see the [Data Restore Using Restore Portal](https://helpcenter.veeam.com/docs/vbo365/guide/ssp_restore.html?ver=70) section of the Veeam Backup for Microsoft 365 User Guide. <div class=\"note\"><strong>NOTE</strong>  </br> To restore data using Restore Portal, you must create a restore session for a restore operator. For more information, see [Create Restore Session for Restore Operator](#tag/RestoreSession/operation/RestoreSession_OperatorExploreAction). </div> 
     * Restore OneDrive Documents by Restore Operator
     */
    oneDriveDocumentOperatorRestore: ({ restoreSessionId, oneDriveId, body }: OneDriveDocumentApiOneDriveDocumentOperatorRestoreRequest, option?: RequestOption): Observable<Optional<RESTOperatorRestoreSessionResponse>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'oneDriveDocumentOperatorRestore');
        throwIfNullOrUndefined(oneDriveId, 'oneDriveId', 'oneDriveDocumentOperatorRestore');
        throwIfNullOrUndefined(body, 'body', 'oneDriveDocumentOperatorRestore');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTOperatorRestoreSessionResponse>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/OneDrives/{oneDriveId}/Documents/operatorRestore'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{oneDriveId}', encodeURI(oneDriveId)),
            method: 'POST',
            headers,
            body: body,
        }, option);
    },

    /**
     * Restores a backed-up OneDrive document with the specified ID to its original location.
     * Restore OneDrive Document
     */
    oneDriveDocumentRestoreDocumentAction: ({ restoreSessionId, oneDriveId, documentId, body }: OneDriveDocumentApiOneDriveDocumentRestoreDocumentActionRequest, option?: RequestOption): Observable<Optional<RESTItemRestoreStatistics>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'oneDriveDocumentRestoreDocumentAction');
        throwIfNullOrUndefined(oneDriveId, 'oneDriveId', 'oneDriveDocumentRestoreDocumentAction');
        throwIfNullOrUndefined(documentId, 'documentId', 'oneDriveDocumentRestoreDocumentAction');
        throwIfNullOrUndefined(body, 'body', 'oneDriveDocumentRestoreDocumentAction');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTItemRestoreStatistics>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/OneDrives/{oneDriveId}/Documents/{documentId}/restore'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{oneDriveId}', encodeURI(oneDriveId)).replace('{documentId}', encodeURI(documentId)),
            method: 'POST',
            headers,
            body: body,
        }, option);
    },

    /**
     * Restores a specific version of a backed-up OneDrive document to its original location.
     * Restore Version of OneDrive Document
     */
    oneDriveDocumentRestoreDocumentActionByVersionId: ({ restoreSessionId, oneDriveId, documentId, versionId, body }: OneDriveDocumentApiOneDriveDocumentRestoreDocumentActionByVersionIdRequest, option?: RequestOption): Observable<Optional<RESTItemRestoreStatistics>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'oneDriveDocumentRestoreDocumentActionByVersionId');
        throwIfNullOrUndefined(oneDriveId, 'oneDriveId', 'oneDriveDocumentRestoreDocumentActionByVersionId');
        throwIfNullOrUndefined(documentId, 'documentId', 'oneDriveDocumentRestoreDocumentActionByVersionId');
        throwIfNullOrUndefined(versionId, 'versionId', 'oneDriveDocumentRestoreDocumentActionByVersionId');
        throwIfNullOrUndefined(body, 'body', 'oneDriveDocumentRestoreDocumentActionByVersionId');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTItemRestoreStatistics>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/OneDrives/{oneDriveId}/Documents/{documentId}/Versions/{versionId}/restore'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{oneDriveId}', encodeURI(oneDriveId)).replace('{documentId}', encodeURI(documentId)).replace('{versionId}', encodeURI(versionId)),
            method: 'POST',
            headers,
            body: body,
        }, option);
    },

    /**
     * Restores backed-up OneDrive documents to their original location.
     * Restore OneDrive Documents
     */
    oneDriveDocumentRestoreDocumentsAction: ({ restoreSessionId, oneDriveId, body }: OneDriveDocumentApiOneDriveDocumentRestoreDocumentsActionRequest, option?: RequestOption): Observable<Optional<RESTItemRestoreStatistics>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'oneDriveDocumentRestoreDocumentsAction');
        throwIfNullOrUndefined(oneDriveId, 'oneDriveId', 'oneDriveDocumentRestoreDocumentsAction');
        throwIfNullOrUndefined(body, 'body', 'oneDriveDocumentRestoreDocumentsAction');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTItemRestoreStatistics>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/OneDrives/{oneDriveId}/Documents/restore'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{oneDriveId}', encodeURI(oneDriveId)),
            method: 'POST',
            headers,
            body: body,
        }, option);
    },

    /**
     * Saves a backed-up OneDrive document with the specified ID.  When you save a document, the request command places the document to a temporary folder on the Veeam Backup for Microsoft 365 server. After that, the document is transferred as application/octet-stream media to the client. To download, read or perform other actions with the octet-stream, use features of programming languages. 
     * Save OneDrive Document
     */
    oneDriveDocumentSaveDocumentAction: ({ restoreSessionId, oneDriveId, documentId, body }: OneDriveDocumentApiOneDriveDocumentSaveDocumentActionRequest, option?: RequestOption): Observable<Optional<object>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'oneDriveDocumentSaveDocumentAction');
        throwIfNullOrUndefined(oneDriveId, 'oneDriveId', 'oneDriveDocumentSaveDocumentAction');
        throwIfNullOrUndefined(documentId, 'documentId', 'oneDriveDocumentSaveDocumentAction');
        throwIfNullOrUndefined(body, 'body', 'oneDriveDocumentSaveDocumentAction');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<object>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/OneDrives/{oneDriveId}/Documents/{documentId}/save'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{oneDriveId}', encodeURI(oneDriveId)).replace('{documentId}', encodeURI(documentId)),
            method: 'POST',
            headers,
            body: body,
        }, option);
    },

    /**
     * Saves a specific version of a backed-up OneDrive document with the specified ID.  When you save a document, the request command places the document to a temporary folder on the Veeam Backup for Microsoft 365 server. After that, the document is transferred as application/octet-stream media to the client. To download, read or perform other actions with the octet-stream, use features of programming languages. 
     * Save Version of OneDrive Document
     */
    oneDriveDocumentSaveDocumentActionByVersionId: ({ restoreSessionId, oneDriveId, documentId, versionId, body }: OneDriveDocumentApiOneDriveDocumentSaveDocumentActionByVersionIdRequest, option?: RequestOption): Observable<Optional<object>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'oneDriveDocumentSaveDocumentActionByVersionId');
        throwIfNullOrUndefined(oneDriveId, 'oneDriveId', 'oneDriveDocumentSaveDocumentActionByVersionId');
        throwIfNullOrUndefined(documentId, 'documentId', 'oneDriveDocumentSaveDocumentActionByVersionId');
        throwIfNullOrUndefined(versionId, 'versionId', 'oneDriveDocumentSaveDocumentActionByVersionId');
        throwIfNullOrUndefined(body, 'body', 'oneDriveDocumentSaveDocumentActionByVersionId');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<object>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/OneDrives/{oneDriveId}/Documents/{documentId}/Versions/{versionId}/save'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{oneDriveId}', encodeURI(oneDriveId)).replace('{documentId}', encodeURI(documentId)).replace('{versionId}', encodeURI(versionId)),
            method: 'POST',
            headers,
            body: body,
        }, option);
    },

    /**
     * Saves backed-up OneDrive documents.  OneDrive documents are always saved in a ZIP archive. When you save backed-up OneDrive documents, the request command archives the documents and places the ZIP archive a temporary folder on the Veeam Backup for Microsoft 365 server. After that, the archive is transferred as application/octet-stream media to the client. To download, read or perform other actions with the octet-stream, use features of programming languages. 
     * Save OneDrive Documents
     */
    oneDriveDocumentSaveDocumentsAction: ({ restoreSessionId, oneDriveId, body }: OneDriveDocumentApiOneDriveDocumentSaveDocumentsActionRequest, option?: RequestOption): Observable<Optional<object>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'oneDriveDocumentSaveDocumentsAction');
        throwIfNullOrUndefined(oneDriveId, 'oneDriveId', 'oneDriveDocumentSaveDocumentsAction');
        throwIfNullOrUndefined(body, 'body', 'oneDriveDocumentSaveDocumentsAction');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<object>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/OneDrives/{oneDriveId}/Documents/save'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{oneDriveId}', encodeURI(oneDriveId)),
            method: 'POST',
            headers,
            body: body,
        }, option);
    },

    /**
     * Sends a backed-up OneDrive document with the specified ID as an attachment in an email message. <div class=\"note\"><strong>NOTE</strong>  </br> To send items as attachments, you must specify the Veeam Explorer for Microsoft OneDrive for Business email settings. For more information, see [Edit Email Notification Settings](#tag/VeodEmailSettings/operation/VeodEmailSettings_Update). </div> 
     * Send OneDrive Document
     */
    oneDriveDocumentSendDocumentAction: ({ restoreSessionId, oneDriveId, documentId, body }: OneDriveDocumentApiOneDriveDocumentSendDocumentActionRequest, option?: RequestOption): Observable<Optional<void>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'oneDriveDocumentSendDocumentAction');
        throwIfNullOrUndefined(oneDriveId, 'oneDriveId', 'oneDriveDocumentSendDocumentAction');
        throwIfNullOrUndefined(documentId, 'documentId', 'oneDriveDocumentSendDocumentAction');
        throwIfNullOrUndefined(body, 'body', 'oneDriveDocumentSendDocumentAction');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<void>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/OneDrives/{oneDriveId}/Documents/{documentId}/send'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{oneDriveId}', encodeURI(oneDriveId)).replace('{documentId}', encodeURI(documentId)),
            method: 'POST',
            headers,
            body: body,
        }, option);
    },

    /**
     * Sends a specific version of a backed-up OneDrive document as an attachment in an email message. <div class=\"note\"><strong>NOTE</strong>  </br> To send items as attachments, you must specify the Veeam Explorer for Microsoft OneDrive for Business email settings. For more information, see [Edit Email Notification Settings](#tag/VeodEmailSettings/operation/VeodEmailSettings_Update). </div> 
     * Send Version of OneDrive Document
     */
    oneDriveDocumentSendDocumentActionByVersionId: ({ restoreSessionId, oneDriveId, documentId, versionId, body }: OneDriveDocumentApiOneDriveDocumentSendDocumentActionByVersionIdRequest, option?: RequestOption): Observable<Optional<void>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'oneDriveDocumentSendDocumentActionByVersionId');
        throwIfNullOrUndefined(oneDriveId, 'oneDriveId', 'oneDriveDocumentSendDocumentActionByVersionId');
        throwIfNullOrUndefined(documentId, 'documentId', 'oneDriveDocumentSendDocumentActionByVersionId');
        throwIfNullOrUndefined(versionId, 'versionId', 'oneDriveDocumentSendDocumentActionByVersionId');
        throwIfNullOrUndefined(body, 'body', 'oneDriveDocumentSendDocumentActionByVersionId');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<void>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/OneDrives/{oneDriveId}/Documents/{documentId}/Versions/{versionId}/send'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{oneDriveId}', encodeURI(oneDriveId)).replace('{documentId}', encodeURI(documentId)).replace('{versionId}', encodeURI(versionId)),
            method: 'POST',
            headers,
            body: body,
        }, option);
    },

    /**
     * Sends backed-up OneDrive documents as attachments in an email message. <div class=\"note\"><strong>NOTE</strong>  </br> To send items as attachments, you must specify the Veeam Explorer for Microsoft OneDrive for Business email settings. For more information, see [Edit Email Notification Settings](#tag/VeodEmailSettings/operation/VeodEmailSettings_Update). </div> 
     * Send OneDrive Documents
     */
    oneDriveDocumentSendDocumentsAction: ({ restoreSessionId, oneDriveId, body }: OneDriveDocumentApiOneDriveDocumentSendDocumentsActionRequest, option?: RequestOption): Observable<Optional<void>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'oneDriveDocumentSendDocumentsAction');
        throwIfNullOrUndefined(oneDriveId, 'oneDriveId', 'oneDriveDocumentSendDocumentsAction');
        throwIfNullOrUndefined(body, 'body', 'oneDriveDocumentSendDocumentsAction');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<void>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/OneDrives/{oneDriveId}/Documents/send'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{oneDriveId}', encodeURI(oneDriveId)),
            method: 'POST',
            headers,
            body: body,
        }, option);
    },

})

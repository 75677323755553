/**
 * The version of the OpenAPI document: v7
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Observable } from 'rxjs';
import { requestAuthenticated, Optional, RequestOption, HttpHeaders, HttpQuery, throwIfNullOrUndefined, encodeURI } from '../runtime';
import {
    PageOfRESTExchangeFolder,
    PageOfRESTExchangeItemsComposed,
    RESTExceptionInfo,
    RESTExchangeFolder,
    RESTExportFolderToPst,
    RESTFoldersRestoreStatistics,
    RESTRestoreToDifferentLocation,
    RESTRestoreToOriginalLocation,
    RESTSearchOptions,
} from '../models';

export interface ExchangeFolderApiExchangeFolderExportToPstActionRequest {
    restoreSessionId: string;
    mailboxId: string;
    folderId: string;
    body: RESTExportFolderToPst;
}

export interface ExchangeFolderApiExchangeFolderGetRequest {
    restoreSessionId: string;
    mailboxId: string;
    offset: number;
    limit: number;
    parentId?: string;
    name?: string;
}

export interface ExchangeFolderApiExchangeFolderGetByFolderIdRequest {
    restoreSessionId: string;
    mailboxId: string;
    folderId: string;
}

export interface ExchangeFolderApiExchangeFolderRestoreActionRequest {
    restoreSessionId: string;
    mailboxId: string;
    folderId: string;
    body: RESTRestoreToOriginalLocation;
}

export interface ExchangeFolderApiExchangeFolderRestoreToActionRequest {
    restoreSessionId: string;
    mailboxId: string;
    folderId: string;
    body: RESTRestoreToDifferentLocation;
}

export interface ExchangeFolderApiExchangeFolderSearchByOptionsRequest {
    restoreSessionId: string;
    mailboxId: string;
    folderId: string;
    offset: number;
    limit: number;
    body: RESTSearchOptions;
    setId?: string;
}

/**
 * no description
 */
export const exchangeFolderApi = ({

    /**
     * Exports backed-up data from an organization mailbox folder with the specified ID to a PST file. <div class=\"important\"><strong> IMPORTANT</strong> </br> To export data to PST (Personal Storage Table) files, you must have a 64-bit version of Microsoft Outlook 2016, Microsoft Outlook 2013 or Microsoft Outlook 2010 installed on a computer running restore sessions. </div> The request command will look for a specified keyword in item names and fields inside the organization mailbox folder. The backed-up data with a specified keyword then will be exported to a PST file and placed to a temporary folder on the Veeam Backup for Microsoft 365 server. After that, the PST file will be transferred as application/octet-stream media to the client. To download, read, convert to PST or perform other actions with the octet-stream, use features of programming languages.  If the size of the exported data exceeds the limit specified for the PST file, Veeam Backup for Microsoft 365 splits the PST file into separate files and exports these files to an archive file of the ZIP format. For example, you specified 1 GB as the PST file size limit. The size of the exported data is 1.5 GB. In this case, Veeam Backup for Microsoft 365 will export data to a ZIP archive. The archive will contain two PST files: one PST file of the 1 GB size and another PST file of the 0.5 GB size. 
     * Export Data from Folders
     */
    exchangeFolderExportToPstAction: ({ restoreSessionId, mailboxId, folderId, body }: ExchangeFolderApiExchangeFolderExportToPstActionRequest, option?: RequestOption): Observable<Optional<object>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'exchangeFolderExportToPstAction');
        throwIfNullOrUndefined(mailboxId, 'mailboxId', 'exchangeFolderExportToPstAction');
        throwIfNullOrUndefined(folderId, 'folderId', 'exchangeFolderExportToPstAction');
        throwIfNullOrUndefined(body, 'body', 'exchangeFolderExportToPstAction');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<object>({
            url: '/v7/RestoreSessions/{restoreSessionId}/organization/mailboxes/{mailboxId}/folders/{folderId}/exportToPst'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{mailboxId}', encodeURI(mailboxId)).replace('{folderId}', encodeURI(folderId)),
            method: 'POST',
            headers,
            body: body,
        }, option);
    },

    /**
     * Returns a collection of organization mailbox folders to explore and restore mailbox folders data.
     * Get Mailbox Folders
     */
    exchangeFolderGet: ({ restoreSessionId, mailboxId, offset, limit, parentId, name }: ExchangeFolderApiExchangeFolderGetRequest, option?: RequestOption): Observable<Optional<PageOfRESTExchangeFolder>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'exchangeFolderGet');
        throwIfNullOrUndefined(mailboxId, 'mailboxId', 'exchangeFolderGet');
        throwIfNullOrUndefined(offset, 'offset', 'exchangeFolderGet');
        throwIfNullOrUndefined(limit, 'limit', 'exchangeFolderGet');

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'offset': offset,
            'limit': limit,
        };

        if (parentId != null) { query['parentId'] = parentId; }
        if (name != null) { query['name'] = name; }

        return requestAuthenticated<PageOfRESTExchangeFolder>({
            url: '/v7/RestoreSessions/{restoreSessionId}/organization/mailboxes/{mailboxId}/folders'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{mailboxId}', encodeURI(mailboxId)),
            method: 'GET',
            query,
        }, option);
    },

    /**
     * Returns a resource representation of an organization mailbox folder with the specified ID.
     * Get Mailbox Folder
     */
    exchangeFolderGetByFolderId: ({ restoreSessionId, mailboxId, folderId }: ExchangeFolderApiExchangeFolderGetByFolderIdRequest, option?: RequestOption): Observable<Optional<RESTExchangeFolder>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'exchangeFolderGetByFolderId');
        throwIfNullOrUndefined(mailboxId, 'mailboxId', 'exchangeFolderGetByFolderId');
        throwIfNullOrUndefined(folderId, 'folderId', 'exchangeFolderGetByFolderId');

        return requestAuthenticated<RESTExchangeFolder>({
            url: '/v7/RestoreSessions/{restoreSessionId}/organization/mailboxes/{mailboxId}/folders/{folderId}'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{mailboxId}', encodeURI(mailboxId)).replace('{folderId}', encodeURI(folderId)),
            method: 'GET',
        }, option);
    },

    /**
     * Restores backed-up data from an organization mailbox folder with the specified ID to the original mailbox.
     * Restore Data from Folders to Original Mailbox
     */
    exchangeFolderRestoreAction: ({ restoreSessionId, mailboxId, folderId, body }: ExchangeFolderApiExchangeFolderRestoreActionRequest, option?: RequestOption): Observable<Optional<RESTFoldersRestoreStatistics>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'exchangeFolderRestoreAction');
        throwIfNullOrUndefined(mailboxId, 'mailboxId', 'exchangeFolderRestoreAction');
        throwIfNullOrUndefined(folderId, 'folderId', 'exchangeFolderRestoreAction');
        throwIfNullOrUndefined(body, 'body', 'exchangeFolderRestoreAction');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTFoldersRestoreStatistics>({
            url: '/v7/RestoreSessions/{restoreSessionId}/organization/mailboxes/{mailboxId}/folders/{folderId}/restore'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{mailboxId}', encodeURI(mailboxId)).replace('{folderId}', encodeURI(folderId)),
            method: 'POST',
            headers,
            body: body,
        }, option);
    },

    /**
     * Restores backed-up data from an organization mailbox folder with the specified ID to another mailbox.
     * Restore Data from Folders to Another Mailbox
     */
    exchangeFolderRestoreToAction: ({ restoreSessionId, mailboxId, folderId, body }: ExchangeFolderApiExchangeFolderRestoreToActionRequest, option?: RequestOption): Observable<Optional<RESTFoldersRestoreStatistics>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'exchangeFolderRestoreToAction');
        throwIfNullOrUndefined(mailboxId, 'mailboxId', 'exchangeFolderRestoreToAction');
        throwIfNullOrUndefined(folderId, 'folderId', 'exchangeFolderRestoreToAction');
        throwIfNullOrUndefined(body, 'body', 'exchangeFolderRestoreToAction');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTFoldersRestoreStatistics>({
            url: '/v7/RestoreSessions/{restoreSessionId}/organization/mailboxes/{mailboxId}/folders/{folderId}/restoreTo'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{mailboxId}', encodeURI(mailboxId)).replace('{folderId}', encodeURI(folderId)),
            method: 'POST',
            headers,
            body: body,
        }, option);
    },

    /**
     * Searches for items in an organization mailbox folder with the specified ID.
     * Search for Exchange Items in Mailbox Folder
     */
    exchangeFolderSearchByOptions: ({ restoreSessionId, mailboxId, folderId, offset, limit, body, setId }: ExchangeFolderApiExchangeFolderSearchByOptionsRequest, option?: RequestOption): Observable<Optional<PageOfRESTExchangeItemsComposed>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'exchangeFolderSearchByOptions');
        throwIfNullOrUndefined(mailboxId, 'mailboxId', 'exchangeFolderSearchByOptions');
        throwIfNullOrUndefined(folderId, 'folderId', 'exchangeFolderSearchByOptions');
        throwIfNullOrUndefined(offset, 'offset', 'exchangeFolderSearchByOptions');
        throwIfNullOrUndefined(limit, 'limit', 'exchangeFolderSearchByOptions');
        throwIfNullOrUndefined(body, 'body', 'exchangeFolderSearchByOptions');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'offset': offset,
            'limit': limit,
        };

        if (setId != null) { query['setId'] = setId; }

        return requestAuthenticated<PageOfRESTExchangeItemsComposed>({
            url: '/v7/RestoreSessions/{restoreSessionId}/organization/mailboxes/{mailboxId}/folders/{folderId}/search'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{mailboxId}', encodeURI(mailboxId)).replace('{folderId}', encodeURI(folderId)),
            method: 'POST',
            headers,
            query,
            body: body,
        }, option);
    },

})

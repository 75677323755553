/**
 * The version of the OpenAPI document: v7
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Observable } from 'rxjs';
import { requestAuthenticated, Optional, RequestOption, HttpHeaders, HttpQuery, throwIfNullOrUndefined, encodeURI } from '../runtime';
import {
    PageOfRESTItemComposed,
    PageOfRESTOneDriveFolder,
    RESTCopyToFolder,
    RESTCopyToFolders,
    RESTExceptionInfo,
    RESTItemRestoreStatistics,
    RESTOneDriveFolder,
    RESTRestoreToOriginal,
    RESTRestoreToOriginalFolders,
    RESTSaveOneDriveFoldersOptions,
    RESTSendAsMsgOptionsV6,
    RESTSendFoldersAsMsgOptions,
    RESTVESPSearchOptions,
} from '../models';

export interface OneDriveFolderApiOneDriveFolderCopyToFolderActionRequest {
    restoreSessionId: string;
    oneDriveId: string;
    folderId: string;
    body: RESTCopyToFolder;
}

export interface OneDriveFolderApiOneDriveFolderCopyToFolderActionByVersionIdRequest {
    restoreSessionId: string;
    oneDriveId: string;
    folderId: string;
    versionId: number;
    body: RESTCopyToFolder;
}

export interface OneDriveFolderApiOneDriveFolderCopyToFoldersActionRequest {
    restoreSessionId: string;
    oneDriveId: string;
    body: RESTCopyToFolders;
}

export interface OneDriveFolderApiOneDriveFolderGetRequest {
    restoreSessionId: string;
    oneDriveId: string;
    offset: number;
    limit: number;
    parentId?: string;
}

export interface OneDriveFolderApiOneDriveFolderGetByIdRequest {
    restoreSessionId: string;
    oneDriveId: string;
    folderId: string;
    versionId?: number;
}

export interface OneDriveFolderApiOneDriveFolderGetByIdByVersionIdRequest {
    restoreSessionId: string;
    oneDriveId: string;
    folderId: string;
    versionId: number;
}

export interface OneDriveFolderApiOneDriveFolderGetVersionsRequest {
    restoreSessionId: string;
    oneDriveId: string;
    folderId: string;
    offset: number;
    limit: number;
}

export interface OneDriveFolderApiOneDriveFolderRestoreFolderActionRequest {
    restoreSessionId: string;
    oneDriveId: string;
    folderId: string;
    body: RESTRestoreToOriginal;
}

export interface OneDriveFolderApiOneDriveFolderRestoreFolderActionByVersionIdRequest {
    restoreSessionId: string;
    oneDriveId: string;
    folderId: string;
    versionId: number;
    body: RESTRestoreToOriginal;
}

export interface OneDriveFolderApiOneDriveFolderRestoreFoldersActionRequest {
    restoreSessionId: string;
    oneDriveId: string;
    body: RESTRestoreToOriginalFolders;
}

export interface OneDriveFolderApiOneDriveFolderSaveFolderActionRequest {
    restoreSessionId: string;
    oneDriveId: string;
    folderId: string;
}

export interface OneDriveFolderApiOneDriveFolderSaveFolderActionByVersionIdRequest {
    restoreSessionId: string;
    oneDriveId: string;
    folderId: string;
    versionId: number;
}

export interface OneDriveFolderApiOneDriveFolderSaveFoldersActionRequest {
    restoreSessionId: string;
    oneDriveId: string;
    body: RESTSaveOneDriveFoldersOptions;
}

export interface OneDriveFolderApiOneDriveFolderSearchOneDriveFolderByOptionsRequest {
    restoreSessionId: string;
    oneDriveId: string;
    folderId: string;
    offset: number;
    limit: number;
    body: RESTVESPSearchOptions;
    setId?: string;
    itemType?: OneDriveFolderSearchOneDriveFolderByOptionsItemTypeEnum;
}

export interface OneDriveFolderApiOneDriveFolderSendFolderActionRequest {
    restoreSessionId: string;
    oneDriveId: string;
    folderId: string;
    body: RESTSendAsMsgOptionsV6;
}

export interface OneDriveFolderApiOneDriveFolderSendFolderActionByVersionIdRequest {
    restoreSessionId: string;
    oneDriveId: string;
    folderId: string;
    versionId: number;
    body: RESTSendAsMsgOptionsV6;
}

export interface OneDriveFolderApiOneDriveFolderSendFoldersActionRequest {
    restoreSessionId: string;
    oneDriveId: string;
    body: RESTSendFoldersAsMsgOptions;
}

/**
 * no description
 */
export const oneDriveFolderApi = ({

    /**
     * Copies a backed-up OneDrive folder with the specified ID to another location in Microsoft OneDrive.
     * Copy OneDrive Folder
     */
    oneDriveFolderCopyToFolderAction: ({ restoreSessionId, oneDriveId, folderId, body }: OneDriveFolderApiOneDriveFolderCopyToFolderActionRequest, option?: RequestOption): Observable<Optional<RESTItemRestoreStatistics>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'oneDriveFolderCopyToFolderAction');
        throwIfNullOrUndefined(oneDriveId, 'oneDriveId', 'oneDriveFolderCopyToFolderAction');
        throwIfNullOrUndefined(folderId, 'folderId', 'oneDriveFolderCopyToFolderAction');
        throwIfNullOrUndefined(body, 'body', 'oneDriveFolderCopyToFolderAction');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTItemRestoreStatistics>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/OneDrives/{oneDriveId}/Folders/{folderId}/copyTo'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{oneDriveId}', encodeURI(oneDriveId)).replace('{folderId}', encodeURI(folderId)),
            method: 'POST',
            headers,
            body: body,
        }, option);
    },

    /**
     * Copies a specific version of a backed-up OneDrive folder to another location in Microsoft OneDrive.
     * Copy Version of OneDrive Folder
     */
    oneDriveFolderCopyToFolderActionByVersionId: ({ restoreSessionId, oneDriveId, folderId, versionId, body }: OneDriveFolderApiOneDriveFolderCopyToFolderActionByVersionIdRequest, option?: RequestOption): Observable<Optional<RESTItemRestoreStatistics>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'oneDriveFolderCopyToFolderActionByVersionId');
        throwIfNullOrUndefined(oneDriveId, 'oneDriveId', 'oneDriveFolderCopyToFolderActionByVersionId');
        throwIfNullOrUndefined(folderId, 'folderId', 'oneDriveFolderCopyToFolderActionByVersionId');
        throwIfNullOrUndefined(versionId, 'versionId', 'oneDriveFolderCopyToFolderActionByVersionId');
        throwIfNullOrUndefined(body, 'body', 'oneDriveFolderCopyToFolderActionByVersionId');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTItemRestoreStatistics>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/OneDrives/{oneDriveId}/Folders/{folderId}/Versions/{versionId}/copyTo'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{oneDriveId}', encodeURI(oneDriveId)).replace('{folderId}', encodeURI(folderId)).replace('{versionId}', encodeURI(versionId)),
            method: 'POST',
            headers,
            body: body,
        }, option);
    },

    /**
     * Copies backed-up OneDrive folders to another location in Microsoft OneDrive.
     * Copy OneDrive Folders
     */
    oneDriveFolderCopyToFoldersAction: ({ restoreSessionId, oneDriveId, body }: OneDriveFolderApiOneDriveFolderCopyToFoldersActionRequest, option?: RequestOption): Observable<Optional<RESTItemRestoreStatistics>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'oneDriveFolderCopyToFoldersAction');
        throwIfNullOrUndefined(oneDriveId, 'oneDriveId', 'oneDriveFolderCopyToFoldersAction');
        throwIfNullOrUndefined(body, 'body', 'oneDriveFolderCopyToFoldersAction');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTItemRestoreStatistics>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/OneDrives/{oneDriveId}/Folders/copyTo'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{oneDriveId}', encodeURI(oneDriveId)),
            method: 'POST',
            headers,
            body: body,
        }, option);
    },

    /**
     * Returns a collection of OneDrive folders to explore and restore.
     * Get OneDrive Folders
     */
    oneDriveFolderGet: ({ restoreSessionId, oneDriveId, offset, limit, parentId }: OneDriveFolderApiOneDriveFolderGetRequest, option?: RequestOption): Observable<Optional<PageOfRESTOneDriveFolder>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'oneDriveFolderGet');
        throwIfNullOrUndefined(oneDriveId, 'oneDriveId', 'oneDriveFolderGet');
        throwIfNullOrUndefined(offset, 'offset', 'oneDriveFolderGet');
        throwIfNullOrUndefined(limit, 'limit', 'oneDriveFolderGet');

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'offset': offset,
            'limit': limit,
        };

        if (parentId != null) { query['parentId'] = parentId; }

        return requestAuthenticated<PageOfRESTOneDriveFolder>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/OneDrives/{oneDriveId}/Folders'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{oneDriveId}', encodeURI(oneDriveId)),
            method: 'GET',
            query,
        }, option);
    },

    /**
     * Returns a resource representation of a backed-up OneDrive folder with the specified ID.
     * Get OneDrive Folder
     */
    oneDriveFolderGetById: ({ restoreSessionId, oneDriveId, folderId, versionId }: OneDriveFolderApiOneDriveFolderGetByIdRequest, option?: RequestOption): Observable<Optional<RESTOneDriveFolder>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'oneDriveFolderGetById');
        throwIfNullOrUndefined(oneDriveId, 'oneDriveId', 'oneDriveFolderGetById');
        throwIfNullOrUndefined(folderId, 'folderId', 'oneDriveFolderGetById');

        const query: HttpQuery = {};

        if (versionId != null) { query['versionId'] = versionId; }

        return requestAuthenticated<RESTOneDriveFolder>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/OneDrives/{oneDriveId}/Folders/{folderId}'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{oneDriveId}', encodeURI(oneDriveId)).replace('{folderId}', encodeURI(folderId)),
            method: 'GET',
            query,
        }, option);
    },

    /**
     * Returns a resource representation of a specific version of a backed-up OneDrive folder with the specified ID.
     * Get Specific Version of OneDrive Folder
     */
    oneDriveFolderGetByIdByVersionId: ({ restoreSessionId, oneDriveId, folderId, versionId }: OneDriveFolderApiOneDriveFolderGetByIdByVersionIdRequest, option?: RequestOption): Observable<Optional<RESTOneDriveFolder>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'oneDriveFolderGetByIdByVersionId');
        throwIfNullOrUndefined(oneDriveId, 'oneDriveId', 'oneDriveFolderGetByIdByVersionId');
        throwIfNullOrUndefined(folderId, 'folderId', 'oneDriveFolderGetByIdByVersionId');
        throwIfNullOrUndefined(versionId, 'versionId', 'oneDriveFolderGetByIdByVersionId');

        return requestAuthenticated<RESTOneDriveFolder>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/OneDrives/{oneDriveId}/Folders/{folderId}/Versions/{versionId}'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{oneDriveId}', encodeURI(oneDriveId)).replace('{folderId}', encodeURI(folderId)).replace('{versionId}', encodeURI(versionId)),
            method: 'GET',
        }, option);
    },

    /**
     * Returns a collection of versions of a backed-up OneDrive folder with the specified ID.  When you get OneDrive folder versions, the server returns information about previous versions of the folder. To get the latest version, use either [Get OneDrive Folder](#tag/OneDriveFolder/operation/OneDriveFolder_GetById) or [Get Specific Version of OneDrive Folder](#tag/OneDriveFolder/operation/OneDriveFolder_GetByIdByVersionId). 
     * Get All Versions of OneDrive Folder
     */
    oneDriveFolderGetVersions: ({ restoreSessionId, oneDriveId, folderId, offset, limit }: OneDriveFolderApiOneDriveFolderGetVersionsRequest, option?: RequestOption): Observable<Optional<PageOfRESTOneDriveFolder>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'oneDriveFolderGetVersions');
        throwIfNullOrUndefined(oneDriveId, 'oneDriveId', 'oneDriveFolderGetVersions');
        throwIfNullOrUndefined(folderId, 'folderId', 'oneDriveFolderGetVersions');
        throwIfNullOrUndefined(offset, 'offset', 'oneDriveFolderGetVersions');
        throwIfNullOrUndefined(limit, 'limit', 'oneDriveFolderGetVersions');

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'offset': offset,
            'limit': limit,
        };

        return requestAuthenticated<PageOfRESTOneDriveFolder>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/OneDrives/{oneDriveId}/Folders/{folderId}/Versions'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{oneDriveId}', encodeURI(oneDriveId)).replace('{folderId}', encodeURI(folderId)),
            method: 'GET',
            query,
        }, option);
    },

    /**
     * Restores a backed-up OneDrive folder with the specified ID to its original location.
     * Restore OneDrive Folder
     */
    oneDriveFolderRestoreFolderAction: ({ restoreSessionId, oneDriveId, folderId, body }: OneDriveFolderApiOneDriveFolderRestoreFolderActionRequest, option?: RequestOption): Observable<Optional<RESTItemRestoreStatistics>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'oneDriveFolderRestoreFolderAction');
        throwIfNullOrUndefined(oneDriveId, 'oneDriveId', 'oneDriveFolderRestoreFolderAction');
        throwIfNullOrUndefined(folderId, 'folderId', 'oneDriveFolderRestoreFolderAction');
        throwIfNullOrUndefined(body, 'body', 'oneDriveFolderRestoreFolderAction');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTItemRestoreStatistics>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/OneDrives/{oneDriveId}/Folders/{folderId}/restore'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{oneDriveId}', encodeURI(oneDriveId)).replace('{folderId}', encodeURI(folderId)),
            method: 'POST',
            headers,
            body: body,
        }, option);
    },

    /**
     * Restores a specific version of a backed-up OneDrive folder to its original location.
     * Restore Version of OneDrive Folder
     */
    oneDriveFolderRestoreFolderActionByVersionId: ({ restoreSessionId, oneDriveId, folderId, versionId, body }: OneDriveFolderApiOneDriveFolderRestoreFolderActionByVersionIdRequest, option?: RequestOption): Observable<Optional<RESTItemRestoreStatistics>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'oneDriveFolderRestoreFolderActionByVersionId');
        throwIfNullOrUndefined(oneDriveId, 'oneDriveId', 'oneDriveFolderRestoreFolderActionByVersionId');
        throwIfNullOrUndefined(folderId, 'folderId', 'oneDriveFolderRestoreFolderActionByVersionId');
        throwIfNullOrUndefined(versionId, 'versionId', 'oneDriveFolderRestoreFolderActionByVersionId');
        throwIfNullOrUndefined(body, 'body', 'oneDriveFolderRestoreFolderActionByVersionId');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTItemRestoreStatistics>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/OneDrives/{oneDriveId}/Folders/{folderId}/Versions/{versionId}/restore'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{oneDriveId}', encodeURI(oneDriveId)).replace('{folderId}', encodeURI(folderId)).replace('{versionId}', encodeURI(versionId)),
            method: 'POST',
            headers,
            body: body,
        }, option);
    },

    /**
     * Restores backed-up OneDrive folders to their original location.
     * Restore OneDrive Folders
     */
    oneDriveFolderRestoreFoldersAction: ({ restoreSessionId, oneDriveId, body }: OneDriveFolderApiOneDriveFolderRestoreFoldersActionRequest, option?: RequestOption): Observable<Optional<RESTItemRestoreStatistics>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'oneDriveFolderRestoreFoldersAction');
        throwIfNullOrUndefined(oneDriveId, 'oneDriveId', 'oneDriveFolderRestoreFoldersAction');
        throwIfNullOrUndefined(body, 'body', 'oneDriveFolderRestoreFoldersAction');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<RESTItemRestoreStatistics>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/OneDrives/{oneDriveId}/Folders/restore'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{oneDriveId}', encodeURI(oneDriveId)),
            method: 'POST',
            headers,
            body: body,
        }, option);
    },

    /**
     * Saves a backed-up OneDrive folder with the specified ID.  OneDrive folders are always saved in a ZIP archive. When you save a backed-up OneDrive folder, the request command archives the folder and places the ZIP archive to a temporary folder on the Veeam Backup for Microsoft 365 server. After that, the archive is transferred as application/octet-stream media to the client. To download, read or perform other actions with the octet-stream, use features of programming languages. 
     * Save OneDrive Folder
     */
    oneDriveFolderSaveFolderAction: ({ restoreSessionId, oneDriveId, folderId }: OneDriveFolderApiOneDriveFolderSaveFolderActionRequest, option?: RequestOption): Observable<Optional<object>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'oneDriveFolderSaveFolderAction');
        throwIfNullOrUndefined(oneDriveId, 'oneDriveId', 'oneDriveFolderSaveFolderAction');
        throwIfNullOrUndefined(folderId, 'folderId', 'oneDriveFolderSaveFolderAction');

        return requestAuthenticated<object>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/OneDrives/{oneDriveId}/Folders/{folderId}/save'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{oneDriveId}', encodeURI(oneDriveId)).replace('{folderId}', encodeURI(folderId)),
            method: 'POST',
        }, option);
    },

    /**
     * Saves a specific version of a backed-up OneDrive folder with the specified ID.  OneDrive folders are always saved in a ZIP archive. When you save a backed-up OneDrive folder, the request command archives the folder and places the ZIP archive to a temporary folder on the Veeam Backup for Microsoft 365 server. After that, the archive is transferred as application/octet-stream media to the client. To download, read or perform other actions with the octet-stream, use features of programming languages. 
     * Save Version of OneDrive Folder
     */
    oneDriveFolderSaveFolderActionByVersionId: ({ restoreSessionId, oneDriveId, folderId, versionId }: OneDriveFolderApiOneDriveFolderSaveFolderActionByVersionIdRequest, option?: RequestOption): Observable<Optional<object>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'oneDriveFolderSaveFolderActionByVersionId');
        throwIfNullOrUndefined(oneDriveId, 'oneDriveId', 'oneDriveFolderSaveFolderActionByVersionId');
        throwIfNullOrUndefined(folderId, 'folderId', 'oneDriveFolderSaveFolderActionByVersionId');
        throwIfNullOrUndefined(versionId, 'versionId', 'oneDriveFolderSaveFolderActionByVersionId');

        return requestAuthenticated<object>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/OneDrives/{oneDriveId}/Folders/{folderId}/Versions/{versionId}/save'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{oneDriveId}', encodeURI(oneDriveId)).replace('{folderId}', encodeURI(folderId)).replace('{versionId}', encodeURI(versionId)),
            method: 'POST',
        }, option);
    },

    /**
     * Saves backed-up OneDrive folders.  OneDrive folders are always saved in a ZIP archive. When you save backed-up OneDrive folders, the request command archives the folders and places the ZIP archive a temporary folder on the Veeam Backup for Microsoft 365 server. After that, the archive is transferred as application/octet-stream media to the client. To download, read or perform other actions with the octet-stream, use features of programming languages. 
     * Save OneDrive Folders
     */
    oneDriveFolderSaveFoldersAction: ({ restoreSessionId, oneDriveId, body }: OneDriveFolderApiOneDriveFolderSaveFoldersActionRequest, option?: RequestOption): Observable<Optional<object>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'oneDriveFolderSaveFoldersAction');
        throwIfNullOrUndefined(oneDriveId, 'oneDriveId', 'oneDriveFolderSaveFoldersAction');
        throwIfNullOrUndefined(body, 'body', 'oneDriveFolderSaveFoldersAction');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<object>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/OneDrives/{oneDriveId}/Folders/save'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{oneDriveId}', encodeURI(oneDriveId)),
            method: 'POST',
            headers,
            body: body,
        }, option);
    },

    /**
     * Searches for items in a backed-up OneDrive folder with the specified ID.
     * Search for OneDrive Items in OneDrive Folder
     */
    oneDriveFolderSearchOneDriveFolderByOptions: ({ restoreSessionId, oneDriveId, folderId, offset, limit, body, setId, itemType }: OneDriveFolderApiOneDriveFolderSearchOneDriveFolderByOptionsRequest, option?: RequestOption): Observable<Optional<PageOfRESTItemComposed>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'oneDriveFolderSearchOneDriveFolderByOptions');
        throwIfNullOrUndefined(oneDriveId, 'oneDriveId', 'oneDriveFolderSearchOneDriveFolderByOptions');
        throwIfNullOrUndefined(folderId, 'folderId', 'oneDriveFolderSearchOneDriveFolderByOptions');
        throwIfNullOrUndefined(offset, 'offset', 'oneDriveFolderSearchOneDriveFolderByOptions');
        throwIfNullOrUndefined(limit, 'limit', 'oneDriveFolderSearchOneDriveFolderByOptions');
        throwIfNullOrUndefined(body, 'body', 'oneDriveFolderSearchOneDriveFolderByOptions');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'offset': offset,
            'limit': limit,
        };

        if (setId != null) { query['setId'] = setId; }
        if (itemType != null) { query['itemType'] = itemType; }

        return requestAuthenticated<PageOfRESTItemComposed>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/OneDrives/{oneDriveId}/Folders/{folderId}/search'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{oneDriveId}', encodeURI(oneDriveId)).replace('{folderId}', encodeURI(folderId)),
            method: 'POST',
            headers,
            query,
            body: body,
        }, option);
    },

    /**
     * Sends a backed-up OneDrive folder with the specified ID as an attachment in an email message. <div class=\"note\"><strong>NOTE</strong>  </br> To send items as attachments, you must specify the Veeam Explorer for Microsoft OneDrive for Business email settings. For more information, see [Edit Email Notification Settings](#tag/VeodEmailSettings/operation/VeodEmailSettings_Update). </div> 
     * Send OneDrive Folder
     */
    oneDriveFolderSendFolderAction: ({ restoreSessionId, oneDriveId, folderId, body }: OneDriveFolderApiOneDriveFolderSendFolderActionRequest, option?: RequestOption): Observable<Optional<void>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'oneDriveFolderSendFolderAction');
        throwIfNullOrUndefined(oneDriveId, 'oneDriveId', 'oneDriveFolderSendFolderAction');
        throwIfNullOrUndefined(folderId, 'folderId', 'oneDriveFolderSendFolderAction');
        throwIfNullOrUndefined(body, 'body', 'oneDriveFolderSendFolderAction');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<void>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/OneDrives/{oneDriveId}/Folders/{folderId}/send'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{oneDriveId}', encodeURI(oneDriveId)).replace('{folderId}', encodeURI(folderId)),
            method: 'POST',
            headers,
            body: body,
        }, option);
    },

    /**
     * Sends a specific version of a backed-up OneDrive folder as an attachment in an email message. <div class=\"note\"><strong>NOTE</strong>  </br> To send items as attachments, you must specify the Veeam Explorer for Microsoft OneDrive for Business email settings. For more information, see [Edit Email Notification Settings](#tag/VeodEmailSettings/operation/VeodEmailSettings_Update). </div> 
     * Send Version of OneDrive Folder
     */
    oneDriveFolderSendFolderActionByVersionId: ({ restoreSessionId, oneDriveId, folderId, versionId, body }: OneDriveFolderApiOneDriveFolderSendFolderActionByVersionIdRequest, option?: RequestOption): Observable<Optional<void>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'oneDriveFolderSendFolderActionByVersionId');
        throwIfNullOrUndefined(oneDriveId, 'oneDriveId', 'oneDriveFolderSendFolderActionByVersionId');
        throwIfNullOrUndefined(folderId, 'folderId', 'oneDriveFolderSendFolderActionByVersionId');
        throwIfNullOrUndefined(versionId, 'versionId', 'oneDriveFolderSendFolderActionByVersionId');
        throwIfNullOrUndefined(body, 'body', 'oneDriveFolderSendFolderActionByVersionId');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<void>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/OneDrives/{oneDriveId}/Folders/{folderId}/Versions/{versionId}/send'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{oneDriveId}', encodeURI(oneDriveId)).replace('{folderId}', encodeURI(folderId)).replace('{versionId}', encodeURI(versionId)),
            method: 'POST',
            headers,
            body: body,
        }, option);
    },

    /**
     * Sends backed-up OneDrive folders as attachments in an email message. <div class=\"note\"><strong>NOTE</strong>  </br> To send items as attachments, you must specify the Veeam Explorer for Microsoft OneDrive for Business email settings. For more information, see [Edit Email Notification Settings](#tag/VeodEmailSettings/operation/VeodEmailSettings_Update). </div> 
     * Send OneDrive Folders
     */
    oneDriveFolderSendFoldersAction: ({ restoreSessionId, oneDriveId, body }: OneDriveFolderApiOneDriveFolderSendFoldersActionRequest, option?: RequestOption): Observable<Optional<void>> => {
        throwIfNullOrUndefined(restoreSessionId, 'restoreSessionId', 'oneDriveFolderSendFoldersAction');
        throwIfNullOrUndefined(oneDriveId, 'oneDriveId', 'oneDriveFolderSendFoldersAction');
        throwIfNullOrUndefined(body, 'body', 'oneDriveFolderSendFoldersAction');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return requestAuthenticated<void>({
            url: '/v7/RestoreSessions/{restoreSessionId}/Organization/OneDrives/{oneDriveId}/Folders/send'.replace('{restoreSessionId}', encodeURI(restoreSessionId)).replace('{oneDriveId}', encodeURI(oneDriveId)),
            method: 'POST',
            headers,
            body: body,
        }, option);
    },

})

/**
 * @export
 * @enum {string}
 */
export enum OneDriveFolderSearchOneDriveFolderByOptionsItemTypeEnum {
    All = 'All',
    Folders = 'Folders',
    Documents = 'Documents'
}

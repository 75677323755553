/**
 * The version of the OpenAPI document: v7
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Observable } from 'rxjs';
import { requestAnonymous, requestAuthenticated, Optional, RequestOption, throwIfNullOrUndefined } from '../runtime';
import {
    OAuthTokenResponse,
    RESTAuthCode,
    RESTExceptionInfo,
} from '../models';

export interface TokenRequest {
    grantType: TokenGrantTypeEnum;
    username?: string;
    password?: string;
    refreshToken?: string;
    clientId?: string;
    assertion?: string;
    integrationToken?: string;
}

/**
 * no description
 */
export const authApi = ({

    /**
     * Creates a one-time authorization code.
     * Request Authorization Code
     */
    authCode: (option?: RequestOption): Observable<Optional<RESTAuthCode>> => {
        return requestAuthenticated<RESTAuthCode>({
            url: '/v7/token/AuthCode',
            method: 'POST',
        }, option);
    },

    /**
     * Performs the logout operation for an authorized user.
     * Log Out
     */
    logout: (option?: RequestOption): Observable<Optional<void>> => {
        return requestAuthenticated<void>({
            url: '/v7/token/logout',
            method: 'POST',
        }, option);
    },

    /**
     * Allows you to authorize your access to the Veeam Backup for Microsoft 365 REST API.
     * Request Authorization Tokens
     */
    token: ({ grantType, username, password, refreshToken, clientId, assertion, integrationToken }: TokenRequest, option?: RequestOption): Observable<Optional<OAuthTokenResponse>> => {
        throwIfNullOrUndefined(grantType, 'grantType', 'token');

        const formData = {
            'grant_type': grantType,
            'username': username,
            'password': password,
            'refresh_token': refreshToken,
            'client_id': clientId,
            'assertion': assertion,
            'integration_token': integrationToken,
        }

        return requestAnonymous<OAuthTokenResponse>({
            url: '/v7/token',
            method: 'POST',
            body: formData,
        }, option);
    },

})

/**
 * @export
 * @enum {string}
 */
export enum TokenGrantTypeEnum {
    Password = 'password',
    RefreshToken = 'refresh_token',
    UrnietfparamsoauthgrantTypejwtBearer = 'urn:ietf:params:oauth:grant-type:jwt-bearer',
    Integration = 'integration',
    Operator = 'operator'
}
